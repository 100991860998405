import {
  RectangleGroupIcon,
  ChartBarIcon,
  CalendarIcon,
  ClockIcon,
  AcademicCapIcon,
  TruckIcon,
  DocumentCheckIcon,
  UserGroupIcon,
  CurrencyDollarIcon,
  ReceiptPercentIcon,
  BanknotesIcon,
  BookOpenIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { NavigationItem } from '../../components/SidebarLayout/SidebarLayout';
import { useAppContext, UserType } from '../../context/appContext';
import { Routes } from '../../utilities/routes';
import useContract, { RestrictedFeatures } from '../useContract';
import useSWR from 'swr';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../data/httpEndpoints';
import useUserReadAccess from '../useUserReadAccess';
import { Permission } from '../../typings/roleConfig';
import { Resource } from '../../typings/backend-types';

export function useSidebarNavigation() {
  const { isFeatureEnabled } = useContract();

  const router = useRouter();
  const { t } = useTranslation('translation', { keyPrefix: 'sidebar' });

  const { userId, userType, user } = useAppContext(UserType.BOTH);

  const hasReadAccess = useUserReadAccess();

  function setCurrentNavItem(pathname: string, navHref: string) {
    if (
      pathname > navHref &&
      pathname.substring(0, navHref.length) === navHref &&
      navHref !== '/'
    ) {
      return true;
    }
    if (pathname === navHref) return true;
  }

  const { data: todoCount } = useSWR<number>(
    () =>
      userType === UserType.EMPLOYEE &&
      (hasReadAccess(Permission.OWN_TODOS) ||
        hasReadAccess(Permission.SENT_TODOS)) &&
      HttpEndpoints.TodoEndpoints.countForAssignedUser(userId, false),
    authenticatedFetcher,
  );

  const studentsNavigation: NavigationItem[] = [
    {
      name: t('dashboard'),
      href: Routes.Dashboard,
      icon: RectangleGroupIcon,
    },
    {
      name: t('progressOverview'),
      href: Routes.ProgressOverview.Overview,
      icon: ChartBarIcon,
      hidden: !isFeatureEnabled(RestrictedFeatures.SYLLABI),
    },
  ];

  const mainNavigation: NavigationItem[] = [
    {
      name: t('dashboard'),
      href: Routes.Dashboard,
      icon: RectangleGroupIcon,
    },
    {
      name: t('calendar'),
      href: Routes.Calendar.CalendarView(),
      icon: CalendarIcon,
      hidden:
        !hasReadAccess(Permission.OWN_CALENDAR) &&
        !hasReadAccess(Permission.ALL_CALENDARS) &&
        !hasReadAccess(Permission.ALL_VEHICLE_CALENDARS) &&
        !hasReadAccess(Permission.OWN_VEHICLE_CALENDAR) &&
        !user.access_rights?.some((r) => r.resource === Resource.Calendar),
    },
    {
      name: t('timeTracking'),
      href: Routes.TimeTracking.Overview(),
      icon: ClockIcon,
      hidden:
        !isFeatureEnabled(RestrictedFeatures.TIME_TRACKING) ||
        (!hasReadAccess(Permission.OWN_TIME_TRACKING) &&
          !hasReadAccess(Permission.ALL_TIME_TRACKING) &&
          !user.access_rights?.some(
            (r) => r.resource === Resource.TimeTracking,
          )),
    },
    {
      name: t('students'),
      href: Routes.Students.Overview,
      icon: AcademicCapIcon,
      hidden:
        !hasReadAccess(Permission.OWN_STUDENTS_INFO) &&
        !hasReadAccess(Permission.ALL_STUDENTS_INFO),
    },
    {
      name: t('vehicles'),
      href: Routes.Vehicles.Overview,
      icon: TruckIcon,
      hidden: !hasReadAccess(Permission.VEHICLES),
    },
    {
      name: t('todoList'),
      href: Routes.Todos.Overview,
      icon: DocumentCheckIcon,
      hidden:
        !hasReadAccess(Permission.OWN_TODOS) &&
        !hasReadAccess(Permission.SENT_TODOS) &&
        !hasReadAccess(Permission.SEND_TODOS),
      badge: todoCount || null,
    },
  ];

  const secondaryNavigation: NavigationItem[] = [
    {
      name: t('users'),
      href: Routes.Users.Overview,
      icon: UserGroupIcon,
      hidden: !hasReadAccess(Permission.USERS),
    },

    {
      name: t('billingTypes'),
      href: Routes.BillingTypes.Overview,
      icon: CurrencyDollarIcon,
      hidden: !hasReadAccess(Permission.BILLING_TYPES),
    },
    {
      name: t('invoices'),
      href: Routes.Invoices.Overview,
      icon: ReceiptPercentIcon,
      hidden:
        !isFeatureEnabled(RestrictedFeatures.FINANCE_OVERVIEW) ||
        !hasReadAccess(Permission.ALL_INVOICES),
    },
    {
      name: t('payments'),
      href: Routes.Payments.Overview,
      icon: BanknotesIcon,
      hidden:
        !hasReadAccess(Permission.ALL_PAYMENTS) ||
        !isFeatureEnabled(RestrictedFeatures.FINANCE_OVERVIEW),
    },
    {
      name: t('syllabi'),
      href: Routes.Syllabi.Overview,
      icon: BookOpenIcon,
      hidden:
        !hasReadAccess(Permission.SYLLABI) ||
        !isFeatureEnabled(RestrictedFeatures.SYLLABI),
    },
    {
      name: t('organization'),
      href: Routes.Organization.DetailView,
      icon: Cog6ToothIcon,
      hidden:
        !hasReadAccess(Permission.ORGANIZATION_SETTINGS) &&
        !hasReadAccess(Permission.PRODUCTS) &&
        !hasReadAccess(Permission.PAYMENT_SETTINGS) &&
        !hasReadAccess(Permission.INVOICE_SETTINGS) &&
        !hasReadAccess(Permission.HOLIDAYS) &&
        !hasReadAccess(Permission.MEETING_POINTS) &&
        !hasReadAccess(Permission.STUDENT_GROUPS) &&
        !hasReadAccess(Permission.DROPOUT_REASONS) &&
        !hasReadAccess(Permission.BRANDING) &&
        !hasReadAccess(Permission.LESSON_REQUEST_CONFIG) &&
        !hasReadAccess(Permission.SMS_SETTINGS) &&
        !hasReadAccess(Permission.EXAMINERS) &&
        !hasReadAccess(Permission.LOCK_PERIODS),
    },
    {
      name: t('successReport'),
      href: Routes.SuccessReport,
      icon: AcademicCapIcon,
      hidden:
        hasReadAccess(Permission.ALL_SUCCESS_REPORTS) ||
        !hasReadAccess(Permission.OWN_SUCCESS_REPORT),
    },
    {
      name: t('successReports'),
      href: Routes.AllSuccessReports,
      icon: AcademicCapIcon,
      hidden:
        !hasReadAccess(Permission.ALL_SUCCESS_REPORTS) ||
        hasReadAccess(Permission.USERS),
    },
  ];

  function processNavigationItems(navigationItems: NavigationItem[]) {
    return navigationItems.map((navEntry) => {
      return {
        ...navEntry,
        current: setCurrentNavItem(router.pathname, navEntry.href),
      };
    });
  }

  return {
    mainNavigation: processNavigationItems(
      userType === UserType.STUDENT ? studentsNavigation : mainNavigation,
    ),
    secondaryNavigation: processNavigationItems(secondaryNavigation),
  };
}
