/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum Color {
  Slate = 'slate',
  Gray = 'gray',
  Zinc = 'zinc',
  Neutral = 'neutral',
  Stone = 'stone',
  Red = 'red',
  Orange = 'orange',
  Amber = 'amber',
  Yellow = 'yellow',
  Lime = 'lime',
  Green = 'green',
  Emerald = 'emerald',
  Teal = 'teal',
  Cyan = 'cyan',
  Sky = 'sky',
  Blue = 'blue',
  Indigo = 'indigo',
  Violet = 'violet',
  Purple = 'purple',
  Fuchsia = 'fuchsia',
  Pink = 'pink',
  Rose = 'rose',
}

export enum LicenseCategory {
  A = 'A',
  A1 = 'A1',
  B = 'B',
  BE = 'BE',
  C = 'C',
  CE = 'CE',
  C1 = 'C1',
  C1E = 'C1E',
  D1 = 'D1',
  D1E = 'D1E',
  D = 'D',
  F = 'F',
  None = 'None',
}

export interface UUIDContainer {
  id: string;
}

export interface WorkTimePercentageChange {
  /** The date of the change, formatted as YYYY-MM-DD, currently with day always at 1 */
  date: string;

  /**
   * @min 0
   * @max 100
   */
  percentage:
    | 0
    | 5
    | 10
    | 15
    | 20
    | 25
    | 30
    | 35
    | 40
    | 45
    | 50
    | 55
    | 60
    | 65
    | 70
    | 75
    | 80
    | 85
    | 90
    | 95
    | 100;
}

export enum Resource {
  Calendar = 'calendar',
  TimeTracking = 'time-tracking',
}

export enum AccessRight {
  Read = 'read',
  Write = 'write',
  ReadWrite = 'read_write',
}

export interface UserIdContainer {
  id: string;
}

export interface UserAccessRightDto {
  resource: Resource;
  access_right: AccessRight;
  accessed_user: UserIdContainer;
}

export interface CreateUserDto {
  calendar_color?: Color;
  licenseCategories?: LicenseCategory[] | null;
  firstName: string;
  lastName: string;
  organization: UUIDContainer;
  email: string;
  archived?: boolean;
  worktime_percentage_changes: WorkTimePercentageChange[];
  access_rights?: UserAccessRightDto[];
  role: UUIDContainer;
  dateOfBirth?: string | null;
  superior?: UserIdContainer | null;
}

export interface Address {
  street?: string;
  number?: string;
  zip?: string;
  city?: string;
  country?: string;
}

export interface FullAddress {
  street?: string;
  number?: string;
  zip?: string;
  city?: string;
  country?: string;
  addressee?: string;
}

export interface ContactInfo {
  address: Address;
  email?: string | null;
  senderEmail: string;
  replyToEmail: string;
  telephoneNum?: string | null;
  website?: string | null;
}

export interface BankingInfo {
  account_owner?: string | null;
  qr_customer_identification_number?: string | null;
  qr_iban?: string | null;
  iban?: string | null;
  bic?: string | null;
  name_of_bank?: string | null;
  vat_id?: string | null;
}

export interface DatatransConfig {
  password: string | null;
  baseUrl: string | null;
  hmacKey: string | null;
  merchantId: string | null;
}

export interface Customizations {
  accent_color: Color;
}

export interface SMSConfiguration {
  eventSMSEnabled: boolean;
  invoiceSMSEnabled: boolean;
}

export interface LessonRequestConfiguration {
  accentColor: Color;
  selectableTimeframes: string[];
  selectableReferrers: string[];

  /** @min 0 */
  formBorderRadius?: number | null;
  font?: string | null;
}

export interface InvoiceType {
  id: string;
  active: boolean;
  title: string;
  description: string;
  due_date_days: number;
  organization: Organization;
  order: number;
}

export interface Organization {
  /**
   * @min 0
   * @max 365
   */
  annual_vacation_days: number;
  holidays?: Record<string, number[]>;

  /** @min 0 */
  full_time_hours: number;

  /** @min 0 */
  lesson_duration_minutes: number;

  /**
   * @min 3
   * @max 5
   */
  progress_card_score_range: number;
  contact_info: ContactInfo;
  banking_info: BankingInfo;
  datatrans_configuration?: DatatransConfig;
  customizations: Customizations;
  sms_configuration: SMSConfiguration;
  lessonRequestConfiguration: LessonRequestConfiguration;
  id: string;
  name: string;
  timezone:
    | 'Africa/Algiers'
    | 'Atlantic/Cape_Verde'
    | 'Africa/Ndjamena'
    | 'Africa/Abidjan'
    | 'Africa/Bamako'
    | 'Africa/Banjul'
    | 'Africa/Conakry'
    | 'Africa/Dakar'
    | 'Africa/Freetown'
    | 'Africa/Lome'
    | 'Africa/Nouakchott'
    | 'Africa/Ouagadougou'
    | 'Atlantic/St_Helena'
    | 'Africa/Cairo'
    | 'Africa/Accra'
    | 'Africa/Bissau'
    | 'Africa/Nairobi'
    | 'Africa/Addis_Ababa'
    | 'Africa/Asmara'
    | 'Africa/Dar_es_Salaam'
    | 'Africa/Djibouti'
    | 'Africa/Kampala'
    | 'Africa/Mogadishu'
    | 'Indian/Antananarivo'
    | 'Indian/Comoro'
    | 'Indian/Mayotte'
    | 'Africa/Monrovia'
    | 'Africa/Tripoli'
    | 'Indian/Mauritius'
    | 'Africa/Casablanca'
    | 'Africa/El_Aaiun'
    | 'Africa/Maputo'
    | 'Africa/Blantyre'
    | 'Africa/Bujumbura'
    | 'Africa/Gaborone'
    | 'Africa/Harare'
    | 'Africa/Kigali'
    | 'Africa/Lubumbashi'
    | 'Africa/Lusaka'
    | 'Africa/Windhoek'
    | 'Africa/Lagos'
    | 'Africa/Bangui'
    | 'Africa/Brazzaville'
    | 'Africa/Douala'
    | 'Africa/Kinshasa'
    | 'Africa/Libreville'
    | 'Africa/Luanda'
    | 'Africa/Malabo'
    | 'Africa/Niamey'
    | 'Africa/Porto-Novo'
    | 'Indian/Reunion'
    | 'Africa/Sao_Tome'
    | 'Indian/Mahe'
    | 'Africa/Johannesburg'
    | 'Africa/Maseru'
    | 'Africa/Mbabane'
    | 'Africa/Khartoum'
    | 'Africa/Juba'
    | 'Africa/Tunis'
    | 'Antarctica/Casey'
    | 'Antarctica/Davis'
    | 'Antarctica/Mawson'
    | 'Indian/Kerguelen'
    | 'Antarctica/DumontDUrville'
    | 'Antarctica/Syowa'
    | 'Antarctica/Troll'
    | 'Antarctica/Vostok'
    | 'Antarctica/Rothera'
    | 'Asia/Kabul'
    | 'Asia/Yerevan'
    | 'Asia/Baku'
    | 'Asia/Dhaka'
    | 'Asia/Thimphu'
    | 'Indian/Chagos'
    | 'Asia/Brunei'
    | 'Asia/Yangon'
    | 'Asia/Shanghai'
    | 'Asia/Urumqi'
    | 'Asia/Hong_Kong'
    | 'Asia/Taipei'
    | 'Asia/Macau'
    | 'Asia/Nicosia'
    | 'Asia/Famagusta'
    | 'Europe/Nicosia'
    | 'Asia/Tbilisi'
    | 'Asia/Dili'
    | 'Asia/Kolkata'
    | 'Asia/Jakarta'
    | 'Asia/Pontianak'
    | 'Asia/Makassar'
    | 'Asia/Jayapura'
    | 'Asia/Tehran'
    | 'Asia/Baghdad'
    | 'Asia/Jerusalem'
    | 'Asia/Tokyo'
    | 'Asia/Amman'
    | 'Asia/Almaty'
    | 'Asia/Qyzylorda'
    | 'Asia/Qostanay'
    | 'Asia/Aqtobe'
    | 'Asia/Aqtau'
    | 'Asia/Atyrau'
    | 'Asia/Oral'
    | 'Asia/Bishkek'
    | 'Asia/Seoul'
    | 'Asia/Pyongyang'
    | 'Asia/Beirut'
    | 'Asia/Kuala_Lumpur'
    | 'Asia/Kuching'
    | 'Indian/Maldives'
    | 'Asia/Hovd'
    | 'Asia/Ulaanbaatar'
    | 'Asia/Choibalsan'
    | 'Asia/Kathmandu'
    | 'Asia/Karachi'
    | 'Asia/Gaza'
    | 'Asia/Hebron'
    | 'Asia/Manila'
    | 'Asia/Qatar'
    | 'Asia/Bahrain'
    | 'Asia/Riyadh'
    | 'Asia/Aden'
    | 'Asia/Kuwait'
    | 'Asia/Singapore'
    | 'Asia/Colombo'
    | 'Asia/Damascus'
    | 'Asia/Dushanbe'
    | 'Asia/Bangkok'
    | 'Asia/Phnom_Penh'
    | 'Asia/Vientiane'
    | 'Asia/Ashgabat'
    | 'Asia/Dubai'
    | 'Asia/Muscat'
    | 'Asia/Samarkand'
    | 'Asia/Tashkent'
    | 'Asia/Ho_Chi_Minh'
    | 'Australia/Darwin'
    | 'Australia/Perth'
    | 'Australia/Eucla'
    | 'Australia/Brisbane'
    | 'Australia/Lindeman'
    | 'Australia/Adelaide'
    | 'Australia/Hobart'
    | 'Australia/Currie'
    | 'Australia/Melbourne'
    | 'Australia/Sydney'
    | 'Australia/Broken_Hill'
    | 'Australia/Lord_Howe'
    | 'Antarctica/Macquarie'
    | 'Indian/Christmas'
    | 'Indian/Cocos'
    | 'Pacific/Fiji'
    | 'Pacific/Gambier'
    | 'Pacific/Marquesas'
    | 'Pacific/Tahiti'
    | 'Pacific/Guam'
    | 'Pacific/Saipan'
    | 'Pacific/Tarawa'
    | 'Pacific/Enderbury'
    | 'Pacific/Kiritimati'
    | 'Pacific/Majuro'
    | 'Pacific/Kwajalein'
    | 'Pacific/Chuuk'
    | 'Pacific/Pohnpei'
    | 'Pacific/Kosrae'
    | 'Pacific/Nauru'
    | 'Pacific/Noumea'
    | 'Pacific/Auckland'
    | 'Pacific/Chatham'
    | 'Antarctica/McMurdo'
    | 'Pacific/Rarotonga'
    | 'Pacific/Niue'
    | 'Pacific/Norfolk'
    | 'Pacific/Palau'
    | 'Pacific/Port_Moresby'
    | 'Pacific/Bougainville'
    | 'Pacific/Pitcairn'
    | 'Pacific/Pago_Pago'
    | 'Pacific/Midway'
    | 'Pacific/Apia'
    | 'Pacific/Guadalcanal'
    | 'Pacific/Fakaofo'
    | 'Pacific/Tongatapu'
    | 'Pacific/Funafuti'
    | 'Pacific/Wake'
    | 'Pacific/Efate'
    | 'Pacific/Wallis'
    | 'Africa/Asmera'
    | 'Africa/Timbuktu'
    | 'America/Argentina/ComodRivadavia'
    | 'America/Atka'
    | 'America/Buenos_Aires'
    | 'America/Catamarca'
    | 'America/Coral_Harbour'
    | 'America/Cordoba'
    | 'America/Ensenada'
    | 'America/Fort_Wayne'
    | 'America/Indianapolis'
    | 'America/Jujuy'
    | 'America/Knox_IN'
    | 'America/Louisville'
    | 'America/Mendoza'
    | 'America/Montreal'
    | 'America/Porto_Acre'
    | 'America/Rosario'
    | 'America/Santa_Isabel'
    | 'America/Shiprock'
    | 'America/Virgin'
    | 'Antarctica/South_Pole'
    | 'Asia/Ashkhabad'
    | 'Asia/Calcutta'
    | 'Asia/Chongqing'
    | 'Asia/Chungking'
    | 'Asia/Dacca'
    | 'Asia/Harbin'
    | 'Asia/Kashgar'
    | 'Asia/Katmandu'
    | 'Asia/Macao'
    | 'Asia/Rangoon'
    | 'Asia/Saigon'
    | 'Asia/Tel_Aviv'
    | 'Asia/Thimbu'
    | 'Asia/Ujung_Pandang'
    | 'Asia/Ulan_Bator'
    | 'Atlantic/Faeroe'
    | 'Atlantic/Jan_Mayen'
    | 'Australia/ACT'
    | 'Australia/Canberra'
    | 'Australia/LHI'
    | 'Australia/NSW'
    | 'Australia/North'
    | 'Australia/Queensland'
    | 'Australia/South'
    | 'Australia/Tasmania'
    | 'Australia/Victoria'
    | 'Australia/West'
    | 'Australia/Yancowinna'
    | 'Brazil/Acre'
    | 'Brazil/DeNoronha'
    | 'Brazil/East'
    | 'Brazil/West'
    | 'Canada/Atlantic'
    | 'Canada/Central'
    | 'Canada/Eastern'
    | 'Canada/Mountain'
    | 'Canada/Newfoundland'
    | 'Canada/Pacific'
    | 'Canada/Saskatchewan'
    | 'Canada/Yukon'
    | 'Chile/Continental'
    | 'Chile/EasterIsland'
    | 'Cuba'
    | 'Egypt'
    | 'Eire'
    | 'Etc/UCT'
    | 'Europe/Belfast'
    | 'Europe/Tiraspol'
    | 'GB'
    | 'GB-Eire'
    | 'GMT+0'
    | 'GMT-0'
    | 'GMT0'
    | 'Greenwich'
    | 'Hongkong'
    | 'Iceland'
    | 'Iran'
    | 'Israel'
    | 'Jamaica'
    | 'Japan'
    | 'Kwajalein'
    | 'Libya'
    | 'Mexico/BajaNorte'
    | 'Mexico/BajaSur'
    | 'Mexico/General'
    | 'NZ'
    | 'NZ-CHAT'
    | 'Navajo'
    | 'PRC'
    | 'Pacific/Johnston'
    | 'Pacific/Ponape'
    | 'Pacific/Samoa'
    | 'Pacific/Truk'
    | 'Pacific/Yap'
    | 'Poland'
    | 'Portugal'
    | 'ROC'
    | 'ROK'
    | 'Singapore'
    | 'Turkey'
    | 'UCT'
    | 'US/Alaska'
    | 'US/Aleutian'
    | 'US/Arizona'
    | 'US/Central'
    | 'US/East-Indiana'
    | 'US/Eastern'
    | 'US/Hawaii'
    | 'US/Indiana-Starke'
    | 'US/Michigan'
    | 'US/Mountain'
    | 'US/Pacific'
    | 'US/Samoa'
    | 'UTC'
    | 'Universal'
    | 'W-SU'
    | 'Zulu'
    | 'Etc/GMT'
    | 'Etc/UTC'
    | 'GMT'
    | 'Etc/Universal'
    | 'Etc/Zulu'
    | 'Etc/Greenwich'
    | 'Etc/GMT-0'
    | 'Etc/GMT+0'
    | 'Etc/GMT0'
    | 'Etc/GMT-14'
    | 'Etc/GMT-13'
    | 'Etc/GMT-12'
    | 'Etc/GMT-11'
    | 'Etc/GMT-10'
    | 'Etc/GMT-9'
    | 'Etc/GMT-8'
    | 'Etc/GMT-7'
    | 'Etc/GMT-6'
    | 'Etc/GMT-5'
    | 'Etc/GMT-4'
    | 'Etc/GMT-3'
    | 'Etc/GMT-2'
    | 'Etc/GMT-1'
    | 'Etc/GMT+1'
    | 'Etc/GMT+2'
    | 'Etc/GMT+3'
    | 'Etc/GMT+4'
    | 'Etc/GMT+5'
    | 'Etc/GMT+6'
    | 'Etc/GMT+7'
    | 'Etc/GMT+8'
    | 'Etc/GMT+9'
    | 'Etc/GMT+10'
    | 'Etc/GMT+11'
    | 'Etc/GMT+12'
    | 'Europe/London'
    | 'Europe/Jersey'
    | 'Europe/Guernsey'
    | 'Europe/Isle_of_Man'
    | 'Europe/Dublin'
    | 'WET'
    | 'CET'
    | 'MET'
    | 'EET'
    | 'Europe/Tirane'
    | 'Europe/Andorra'
    | 'Europe/Vienna'
    | 'Europe/Minsk'
    | 'Europe/Brussels'
    | 'Europe/Sofia'
    | 'Europe/Prague'
    | 'Europe/Copenhagen'
    | 'Atlantic/Faroe'
    | 'America/Danmarkshavn'
    | 'America/Scoresbysund'
    | 'America/Godthab'
    | 'America/Thule'
    | 'Europe/Tallinn'
    | 'Europe/Helsinki'
    | 'Europe/Mariehamn'
    | 'Europe/Paris'
    | 'Europe/Berlin'
    | 'Europe/Busingen'
    | 'Europe/Gibraltar'
    | 'Europe/Athens'
    | 'Europe/Budapest'
    | 'Atlantic/Reykjavik'
    | 'Europe/Rome'
    | 'Europe/Vatican'
    | 'Europe/San_Marino'
    | 'Europe/Riga'
    | 'Europe/Vaduz'
    | 'Europe/Vilnius'
    | 'Europe/Luxembourg'
    | 'Europe/Malta'
    | 'Europe/Chisinau'
    | 'Europe/Monaco'
    | 'Europe/Amsterdam'
    | 'Europe/Oslo'
    | 'Arctic/Longyearbyen'
    | 'Europe/Warsaw'
    | 'Europe/Lisbon'
    | 'Atlantic/Azores'
    | 'Atlantic/Madeira'
    | 'Europe/Bucharest'
    | 'Europe/Kaliningrad'
    | 'Europe/Moscow'
    | 'Europe/Simferopol'
    | 'Europe/Astrakhan'
    | 'Europe/Volgograd'
    | 'Europe/Saratov'
    | 'Europe/Kirov'
    | 'Europe/Samara'
    | 'Europe/Ulyanovsk'
    | 'Asia/Yekaterinburg'
    | 'Asia/Omsk'
    | 'Asia/Barnaul'
    | 'Asia/Novosibirsk'
    | 'Asia/Tomsk'
    | 'Asia/Novokuznetsk'
    | 'Asia/Krasnoyarsk'
    | 'Asia/Irkutsk'
    | 'Asia/Chita'
    | 'Asia/Yakutsk'
    | 'Asia/Vladivostok'
    | 'Asia/Khandyga'
    | 'Asia/Sakhalin'
    | 'Asia/Magadan'
    | 'Asia/Srednekolymsk'
    | 'Asia/Ust-Nera'
    | 'Asia/Kamchatka'
    | 'Asia/Anadyr'
    | 'Europe/Belgrade'
    | 'Europe/Ljubljana'
    | 'Europe/Podgorica'
    | 'Europe/Sarajevo'
    | 'Europe/Skopje'
    | 'Europe/Zagreb'
    | 'Europe/Bratislava'
    | 'Europe/Madrid'
    | 'Africa/Ceuta'
    | 'Atlantic/Canary'
    | 'Europe/Stockholm'
    | 'Europe/Zurich'
    | 'Europe/Istanbul'
    | 'Asia/Istanbul'
    | 'Europe/Kiev'
    | 'Europe/Uzhgorod'
    | 'Europe/Zaporozhye'
    | 'Factory'
    | 'EST'
    | 'MST'
    | 'HST'
    | 'EST5EDT'
    | 'CST6CDT'
    | 'MST7MDT'
    | 'PST8PDT'
    | 'America/New_York'
    | 'America/Chicago'
    | 'America/North_Dakota/Center'
    | 'America/North_Dakota/New_Salem'
    | 'America/North_Dakota/Beulah'
    | 'America/Denver'
    | 'America/Los_Angeles'
    | 'America/Juneau'
    | 'America/Sitka'
    | 'America/Metlakatla'
    | 'America/Yakutat'
    | 'America/Anchorage'
    | 'America/Nome'
    | 'America/Adak'
    | 'Pacific/Honolulu'
    | 'America/Phoenix'
    | 'America/Boise'
    | 'America/Indiana/Indianapolis'
    | 'America/Indiana/Marengo'
    | 'America/Indiana/Vincennes'
    | 'America/Indiana/Tell_City'
    | 'America/Indiana/Petersburg'
    | 'America/Indiana/Knox'
    | 'America/Indiana/Winamac'
    | 'America/Indiana/Vevay'
    | 'America/Kentucky/Louisville'
    | 'America/Kentucky/Monticello'
    | 'America/Detroit'
    | 'America/Menominee'
    | 'America/St_Johns'
    | 'America/Goose_Bay'
    | 'America/Halifax'
    | 'America/Glace_Bay'
    | 'America/Moncton'
    | 'America/Blanc-Sablon'
    | 'America/Toronto'
    | 'America/Thunder_Bay'
    | 'America/Nipigon'
    | 'America/Rainy_River'
    | 'America/Atikokan'
    | 'America/Winnipeg'
    | 'America/Regina'
    | 'America/Swift_Current'
    | 'America/Edmonton'
    | 'America/Vancouver'
    | 'America/Dawson_Creek'
    | 'America/Fort_Nelson'
    | 'America/Creston'
    | 'America/Pangnirtung'
    | 'America/Iqaluit'
    | 'America/Resolute'
    | 'America/Rankin_Inlet'
    | 'America/Cambridge_Bay'
    | 'America/Yellowknife'
    | 'America/Inuvik'
    | 'America/Whitehorse'
    | 'America/Dawson'
    | 'America/Cancun'
    | 'America/Merida'
    | 'America/Matamoros'
    | 'America/Monterrey'
    | 'America/Mexico_City'
    | 'America/Ojinaga'
    | 'America/Chihuahua'
    | 'America/Hermosillo'
    | 'America/Mazatlan'
    | 'America/Bahia_Banderas'
    | 'America/Tijuana'
    | 'America/Nassau'
    | 'America/Barbados'
    | 'America/Belize'
    | 'Atlantic/Bermuda'
    | 'America/Costa_Rica'
    | 'America/Havana'
    | 'America/Santo_Domingo'
    | 'America/El_Salvador'
    | 'America/Guatemala'
    | 'America/Port-au-Prince'
    | 'America/Tegucigalpa'
    | 'America/Jamaica'
    | 'America/Martinique'
    | 'America/Managua'
    | 'America/Panama'
    | 'America/Cayman'
    | 'America/Puerto_Rico'
    | 'America/Miquelon'
    | 'America/Grand_Turk'
    | 'US/Pacific-New'
    | 'America/Argentina/Buenos_Aires'
    | 'America/Argentina/Cordoba'
    | 'America/Argentina/Salta'
    | 'America/Argentina/Tucuman'
    | 'America/Argentina/La_Rioja'
    | 'America/Argentina/San_Juan'
    | 'America/Argentina/Jujuy'
    | 'America/Argentina/Catamarca'
    | 'America/Argentina/Mendoza'
    | 'America/Argentina/San_Luis'
    | 'America/Argentina/Rio_Gallegos'
    | 'America/Argentina/Ushuaia'
    | 'America/Aruba'
    | 'America/La_Paz'
    | 'America/Noronha'
    | 'America/Belem'
    | 'America/Santarem'
    | 'America/Fortaleza'
    | 'America/Recife'
    | 'America/Araguaina'
    | 'America/Maceio'
    | 'America/Bahia'
    | 'America/Sao_Paulo'
    | 'America/Campo_Grande'
    | 'America/Cuiaba'
    | 'America/Porto_Velho'
    | 'America/Boa_Vista'
    | 'America/Manaus'
    | 'America/Eirunepe'
    | 'America/Rio_Branco'
    | 'America/Santiago'
    | 'America/Punta_Arenas'
    | 'Pacific/Easter'
    | 'Antarctica/Palmer'
    | 'America/Bogota'
    | 'America/Curacao'
    | 'America/Lower_Princes'
    | 'America/Kralendijk'
    | 'America/Guayaquil'
    | 'Pacific/Galapagos'
    | 'Atlantic/Stanley'
    | 'America/Cayenne'
    | 'America/Guyana'
    | 'America/Asuncion'
    | 'America/Lima'
    | 'Atlantic/South_Georgia'
    | 'America/Paramaribo'
    | 'America/Port_of_Spain'
    | 'America/Anguilla'
    | 'America/Antigua'
    | 'America/Dominica'
    | 'America/Grenada'
    | 'America/Guadeloupe'
    | 'America/Marigot'
    | 'America/Montserrat'
    | 'America/St_Barthelemy'
    | 'America/St_Kitts'
    | 'America/St_Lucia'
    | 'America/St_Thomas'
    | 'America/St_Vincent'
    | 'America/Tortola'
    | 'America/Montevideo'
    | 'America/Caracas';
  usesCustomLogo: boolean;
  paymentRequestText: string;
  invoiceTypes?: InvoiceType[];
  students?: Student[];
}

export interface CarryOverManualEdit {
  /**
   * @min 0
   * @max 11
   */
  month: number;
  delta: number;
  comment: string;
}

export interface TimesheetMetadata {
  id: string;
  year: string;
  user?: User;
  carryOverManualEdits: CarryOverManualEdit[];
  vacationManualEdits: CarryOverManualEdit[];
}

export interface ICalSubscription {
  display_color: Color;
  id: string;
  title: string;
  subscriptionUrl: string;
  user: User;
}

export enum CalendarAccessType {
  FullAccess = 'full_access',
  AvailabilityOnly = 'availability_only',
}

export interface CalendarExport {
  access_type: CalendarAccessType;
  id: string;
  label?: string;
  user: User;
}

export enum GearType {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export enum OwnershipType {
  Owned = 'Owned',
  Leasing = 'Leasing',
}

export interface MileageHistoryEntry {
  /** @format date-time */
  date: string;
  mileage: number;
}

export interface VehicleInfo {
  engine?: string;
  model?: string;
  gearType?: GearType;

  /** @format date-time */
  firstDriven?: string;
  ownershipType?: OwnershipType;

  /** @format date-time */
  endOfLeasingContract?: string;
  leasingCompany?: string;
  leasingContractNumber?: string;
  location?: string;
  chassisNumber?: string;
  mileageHistory?: MileageHistoryEntry[];
}

export interface Vehicle {
  licenseCategory: LicenseCategory;

  /** Another license category the vehicle can be used for, e.g. B and BE for most cars. */
  secondaryLicenseCategory?: LicenseCategory;
  id?: string;
  license_plate: string;
  title?: string;
  additional_info?: VehicleInfo;
  organization: Organization;
  assigned_instructors?: User[];
  main_instructor?: User;
  active: boolean;
}

export interface UserAccessRight {
  resource: Resource;
  access_right: AccessRight;
  id: string;
  right_holder?: User;
  accessed_user?: User;
}

export enum DashboardComponent {
  MY_DAY = 'MY_DAY',
  ALL_STUDENT_TASKS = 'ALL_STUDENT_TASKS',
  OWN_STUDENT_TASKS = 'OWN_STUDENT_TASKS',
  OVERDUE_INVOICES = 'OVERDUE_INVOICES',
  EMPLOYEE_BIRTHDAYS = 'EMPLOYEE_BIRTHDAYS',
  MY_STUDENTS = 'MY_STUDENTS',
  ALL_STUDENTS_WITHOUT_APPOINTMENT = 'ALL_STUDENTS_WITHOUT_APPOINTMENT',
  OWN_STUDENTS_INFO_WITHOUT_APPOINTMENT = 'OWN_STUDENTS_INFO_WITHOUT_APPOINTMENT',
  MY_LESSON_REQUESTS = 'MY_LESSON_REQUESTS',
  ALL_LESSON_REQUESTS = 'ALL_LESSON_REQUESTS',
  TODO_COUNT = 'TODO_COUNT',
}

export interface Role {
  permissions: Record<string, number>;
  dashboardComponents: DashboardComponent[];
  id: string;
  title: string;
  isAdmin: boolean;
  organization: Organization;
}

export interface User {
  worktime_percentage_changes: WorkTimePercentageChange[];
  calendar_color: Color;
  licenseCategories?: LicenseCategory[] | null;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  archived: boolean;
  students?: Student[];
  timesheets?: TimesheetMetadata[];
  icalSubscriptions?: ICalSubscription[];
  calendarExports?: CalendarExport[];
  assigned_vehicles?: Vehicle[];
  organization?: Organization;
  access_rights?: UserAccessRight[];
  role: Role;

  /** @format date-time */
  dateOfBirth?: string | null;
  superior?: User | null;
}

export interface Section {
  title: string;
  lesson_ids: string[];
}

export interface Lesson {
  id: string;
  title: string;
  syllabus?: Syllabus;
}

export interface Syllabus {
  licenseCategory: LicenseCategory;
  sections: Section[];
  id: string;
  title: string;
  archived: boolean;
  lessons?: Lesson[];
  organization: Organization;
}

export interface SyllabusProgressCard {
  id: string;
  syllabus: Syllabus;
  student: Student;
}

export enum PaymentType {
  Banking = 'Banking',
  TWINT = 'TWINT',
  Cash = 'Cash',
  Correction = 'Correction',
}

export interface BillingTypeTag {
  id: string;
  title: string;
  billingTypes?: BillingType[];
  organization: Organization;
}

export interface BillingType {
  licenseCategory: LicenseCategory;
  id: string;
  title: string;
  duration_minutes: number;
  shadow_time: number;
  price?: number;
  requiresDescription: boolean;
  organization: Organization;
  trackable: boolean;
  billingTypeTag?: BillingTypeTag;

  /** @format date-time */
  endOfLife?: string;
  predecessor?: BillingType;
}

export interface MeetingPoint {
  id: string;
  title: string;
  organization: Organization;
  address: Address;
}

export interface Exam {
  licenseCategory: LicenseCategory;
  id: string;
  student: Student;
  examAttempts?: ExamAttempt[];
  organization: Organization;
}

export interface ExamAttempt {
  id: string;

  /**
   * @min 1
   * @max 4
   */
  attempt_number: number;

  /** @format date-time */
  exam_date?: string;
  examiner: string;
  passed: boolean;
  comment?: string;
  exam?: Exam;
  event?: WorkEvent;
  organization?: Organization;
  instructor?: User;
}

export interface WorkEvent {
  billingType?: BillingType;
  meetingPoint?: MeetingPoint;
  meetingAddress: Address;
  student?: Student;
  vehicle?: Vehicle;
  secondaryVehicle?: Vehicle;
  examAttempt?: ExamAttempt;
  id: string;
  description?: string;

  /** @format date-time */
  start_time: string;

  /** @format date-time */
  end_time: string;
  user?: User;
  organization: Organization;
}

export interface Product {
  assignedForLicenseCategory?: LicenseCategory;
  id: string;
  title: string;
  price: number;
  organization: Organization;
}

export interface InvoicePosition {
  id?: string;
  title: string;
  price: number;
  event?: WorkEvent;
  product?: Product;

  /** @format date-time */
  date?: string;
  invoice?: Invoice;
}

export interface InvoiceHistoryItem {
  id: string;
  invoice: Invoice;
  sent_date?: string;

  /** @format date-time */
  due_date?: string;
  invoice_type?: InvoiceType;

  /** @format date-time */
  created_date: string;
  percentage?: number | null;
  recipient_name?: string | null;
}

export interface Invoice {
  id: string;
  invoice_number?: number;
  finalized_at?: string;
  cancelled_on: string | null;

  /** @format date-time */
  accounting_period_start?: string;

  /** @format date-time */
  accounting_period_end?: string;
  student?: Student;
  positions: InvoicePosition[];
  organization: Organization;
  payments?: Payment[];
  invoiceHistory?: InvoiceHistoryItem[];
}

export interface Payment {
  paymentType: PaymentType;
  id: string;
  amount: number;
  student?: Student;
  description?: string;

  /** @format date-time */
  date: string;

  /** @format date-time */
  created_date: string;
  isProcessedAutomatically: boolean;
  invoice?: Invoice;
}

export interface DropOutReason {
  id: string;
  organization: Organization;
  text: string;
}

export interface DropOutItem {
  id: string;
  student: Student;
  reason: DropOutReason;

  /** @format date-time */
  date: string;
  remark: string | null;
}

export interface StudentGroup {
  id: string;
  title: string;
  students?: Student[];
  organization: Organization;
}

export interface Student {
  /** Filenames of the attachments uploaded for this student, e.g. lernfahrausweis.pdf. Suffix of the GCP Cloud Storage object path that is constructed for each file. */
  attachmentFilenames: string[];
  label?: Color | null;
  id: string;
  firstName: string;
  lastName: string;
  auth0Id: string | null;
  balance?: number;
  occupation?: string | null;

  /** @format date-time */
  dateOfBirth?: string | null;
  student_number: number;
  notes?: string | null;
  email: string;
  billingEmail?: string;
  phone: string;
  secondaryPhone?: string | null;
  primaryAddress: Address;
  billingAddress: FullAddress;
  archived: boolean;
  organization?: Organization;
  instructor?: User;
  syllabusProgressCards?: SyllabusProgressCard[];
  payments?: Payment[];
  dropoutItem?: DropOutItem | null;

  /** @format date-time */
  createdDate?: string;
  groups: StudentGroup[];
  exams?: Exam[];
  licenseCategories?: string;
}

export interface LessonRequest {
  licenseCategory: LicenseCategory;
  requestedTimeframes: string[];
  id: string;

  /** @format date-time */
  createdDate: string;

  /** @format date-time */
  completedDate: string | null;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  address: Address;
  requestedInstructor: User | null;
  organization: Organization;
  referrer: string | null;
  message: string | null;
  student: Student | null;
  assignmentHistory?: LessonRequestAssignmentHistory[];
}

export interface LessonRequestAssignmentHistory {
  id: string;

  /** @format date-time */
  createdDate: string;
  assignedBy: User | null;
  assignedTo: User | null;
  lessonRequest: LessonRequest;
}

export interface VacationEvent {
  id: string;
  description?: string;

  /** @format date-time */
  start_time: string;

  /** @format date-time */
  end_time: string;
  user?: User;
  organization: Organization;
}

export interface BillingTypeWorkTime {
  licenseCategory: LicenseCategory;
  billingTypeId: string;
  billingTypeTitle: string;
  billingTypeDuration: number;
  billingTypeShadow: number;
  shadowTimeSum: number;
  tagTitle: string;
  workingTimeMinutes: number;
  priceSum: number;
  percentage: number;
}

export interface ICalEvent {
  type: string;

  /** @format date-time */
  start: string;

  /** @format date-time */
  end: string;
  summary: string;
  description: string;
  subscriptionId: string;
}

export enum ExamAttemptsSort {
  DATE = 'DATE',
  EXAMINER = 'EXAMINER',
  RESULT = 'RESULT',
  STUDENT = 'STUDENT',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface UpdateUserDto {
  calendar_color?: Color;
  licenseCategories?: LicenseCategory[] | null;
  firstName?: string;
  lastName?: string;
  organization?: UUIDContainer;
  email?: string;
  archived?: boolean;
  worktime_percentage_changes?: WorkTimePercentageChange[];
  access_rights?: UserAccessRightDto[];
  dateOfBirth?: string | null;
  superior?: UserIdContainer | null;
}

export interface UserCalendarColorDto {
  calendar_color: Color;
}

export interface UserRoleDto {
  role: UUIDContainer;
}

export interface UserBirthday {
  firstName: string;
  lastName: string;

  /** @format date-time */
  nextBirthday: string;

  /** @format date-time */
  dateOfBirth: string;
}

export interface BasicUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  archived: boolean;
}

export interface InstructorInfo {
  id: string;
  firstName: string;
  lastName: string;
}

export enum StudentSort {
  NAME = 'NAME',
  INSTRUCTOR = 'INSTRUCTOR',
}

export enum InvoiceStatusFilter {
  PAID = 'PAID',
  PARTLY_PAID = 'PARTLY_PAID',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  CANCELLED = 'CANCELLED',
}

export interface InvoiceSummary {
  reference?: string;
  total: number;
  invoice_number?: number;
  finalized_at?: string;
  id?: string;

  /** @format date-time */
  payment_due_date?: string;
  paid_already?: number;
  student?: Student;

  /** @format date-time */
  accounting_period_start?: string;

  /** @format date-time */
  accounting_period_end?: string;
}

export interface WorkEventDefaultValues {
  billingType?: BillingType;
  primaryVehicle?: Vehicle;
  secondaryVehicle?: Vehicle;
  meetingPoint?: MeetingPoint;
  meetingAddress?: Address;
  eventEnd?: string;
}

export interface CreateStudentDto {
  firstName: string;
  label?: Color | null;
  lastName: string;

  /** @format date-time */
  dateOfBirth?: string;
  instructor: UUIDContainer;
  phone: string;
  secondaryPhone?: string;
  email?: string;
  billingEmail?: string;
  notes?: string;
  primaryAddress?: Address;
  billingAddress?: FullAddress;
  archived?: boolean;
  groups?: UUIDContainer[];
  occupation?: string;
  auth0Id?: string | null;
}

export interface UpdateStudentDto {
  firstName?: string;
  label?: Color | null;
  lastName?: string;

  /** @format date-time */
  dateOfBirth?: string;
  instructor?: UUIDContainer;
  phone?: string;
  secondaryPhone?: string;
  email?: string;
  billingEmail?: string;
  notes?: string;
  primaryAddress?: Address;
  billingAddress?: FullAddress;
  archived?: boolean;
  groups?: UUIDContainer[];
  occupation?: string;
  auth0Id?: string | null;
}

export interface CreateSyllabusProgressCardDto {
  syllabusId: string;
  student: UUIDContainer;
}

export interface SyllabusItemScore {
  comment: string | null;
  progressCardId: string;
  progressCard?: SyllabusProgressCard;
  calendarEventId: string;
  calendarEvent: WorkEvent;
  lessonId: string;
  lesson: Lesson;
  score: string;
}

export interface CreateSyllabusItemScoreDto {
  progressCard: UUIDContainer;
  calendarEvent: UUIDContainer;
  lesson: UUIDContainer;
  score: string;
  comment?: string | null;
}

export interface UpdateSyllabusItemScoreDto {
  progressCard: UUIDContainer;
  calendarEvent: UUIDContainer;
  lesson: UUIDContainer;
  score?: string;
  comment?: string | null;
}

export interface CreateVacationEventsDto {
  description: string;
  start_time: string;
  end_time: string;
  user: UserIdContainer;
  organization: UUIDContainer;
}

export interface UpdateVacationEventsDto {
  description?: string;
  start_time?: string;
  end_time?: string;
  user?: UserIdContainer;
  organization?: UUIDContainer;
}

export interface CreateWorkEventDto {
  description?: string;
  start_time: string;
  end_time: string;
  billingTypeId: string;
  organizationId: string;
  user: UserIdContainer;
  student?: UUIDContainer;
  examAttempt?: UUIDContainer;
  vehicleId?: string;
  secondaryVehicleId?: string;
  meetingPointId?: string;
  meetingAddress?: Address;
}

export interface UpdateWorkEventDto {
  description?: string;
  start_time?: string;
  end_time?: string;
  billingTypeId?: string;
  organizationId?: string;
  user?: UserIdContainer;
  student?: UUIDContainer;
  examAttempt?: UUIDContainer;
  vehicleId?: string;
  secondaryVehicleId?: string;
  meetingPointId?: string;
  meetingAddress?: Address;
}

export interface CalendarEntry {
  id: string;
  description?: string;

  /** @format date-time */
  start_time: string;

  /** @format date-time */
  end_time: string;
  user?: User;
  organization: Organization;
}

export interface CreatePaymentDto {
  paymentType: PaymentType;
  amount: number;
  student: UUIDContainer;
  description?: string;

  /** @format date-time */
  date: string;
  isProcessedAutomatically?: boolean;
}

export interface DateContainer {
  Dt: string;
}

export interface CreditorReferenceInfo {
  Ref: string;
}

export interface Structured {
  CdtrRefInf: CreditorReferenceInfo;
}

export interface RemittanceInfo {
  Strd: Structured;
}

export interface TransactionDetails {
  RmtInf: RemittanceInfo;
  Amt: number;
}

export interface EntryDetails {
  TxDtls: TransactionDetails[];
}

export interface Entry {
  ValDt: DateContainer;
  NtryDtls: EntryDetails;
}

export interface Notification {
  Ntry: Entry[];
}

export interface NotificationContainer {
  Ntfctn: Notification;
}

export interface ISOBankStatement {
  BkToCstmrDbtCdtNtfctn: NotificationContainer;
}

export interface BankStatementImportResponse {
  reference: string;
  amount: number;
  date: string;
  responseTranslationkey: string;
  student?: Student;
}

export interface UsageLimitsAndFeatureFlags {
  FF_sms: boolean;
  FF_mailing: boolean;
  FF_student_login: boolean;
  FF_time_tracking: boolean;
  FF_finance_overview: boolean;
  FF_payment_processing: boolean;
  FF_syllabi: boolean;
  FF_payment_import: boolean;
  FF_invoice_deletion: boolean;
  UL_maxUsers: number | null;
  UL_maxVehicles: number | null;
}

export interface AppUsagePackage {
  FF_sms: boolean;
  FF_mailing: boolean;
  FF_student_login: boolean;
  FF_time_tracking: boolean;
  FF_finance_overview: boolean;
  FF_payment_processing: boolean;
  FF_syllabi: boolean;
  FF_payment_import: boolean;
  FF_invoice_deletion: boolean;
  UL_maxUsers: number | null;
  UL_maxVehicles: number | null;
  id: string;
  archived: boolean;
  title: string;
}

export interface AppUsageContract {
  FF_sms: boolean;
  FF_mailing: boolean;
  FF_student_login: boolean;
  FF_time_tracking: boolean;
  FF_finance_overview: boolean;
  FF_payment_processing: boolean;
  FF_syllabi: boolean;
  FF_payment_import: boolean;
  FF_invoice_deletion: boolean;
  UL_maxUsers: number | null;
  UL_maxVehicles: number | null;
  id: string;

  /** @format date-time */
  activeFrom: string | null;

  /** @format date-time */
  activeUntil: string | null;
  organization: Organization;
  package?: AppUsagePackage | null;
}

export interface OrganizationWithStats {
  /**
   * @min 0
   * @max 365
   */
  annual_vacation_days: number;
  holidays?: Record<string, number[]>;

  /** @min 0 */
  full_time_hours: number;

  /** @min 0 */
  lesson_duration_minutes: number;

  /**
   * @min 3
   * @max 5
   */
  progress_card_score_range: number;
  contact_info: ContactInfo;
  banking_info: BankingInfo;
  datatrans_configuration?: DatatransConfig;
  customizations: Customizations;
  sms_configuration: SMSConfiguration;
  lessonRequestConfiguration: LessonRequestConfiguration;
  userCount: number;
  studentCount: number;
  activeContract: AppUsageContract | null;
  id: string;
  name: string;
  timezone:
    | 'Africa/Algiers'
    | 'Atlantic/Cape_Verde'
    | 'Africa/Ndjamena'
    | 'Africa/Abidjan'
    | 'Africa/Bamako'
    | 'Africa/Banjul'
    | 'Africa/Conakry'
    | 'Africa/Dakar'
    | 'Africa/Freetown'
    | 'Africa/Lome'
    | 'Africa/Nouakchott'
    | 'Africa/Ouagadougou'
    | 'Atlantic/St_Helena'
    | 'Africa/Cairo'
    | 'Africa/Accra'
    | 'Africa/Bissau'
    | 'Africa/Nairobi'
    | 'Africa/Addis_Ababa'
    | 'Africa/Asmara'
    | 'Africa/Dar_es_Salaam'
    | 'Africa/Djibouti'
    | 'Africa/Kampala'
    | 'Africa/Mogadishu'
    | 'Indian/Antananarivo'
    | 'Indian/Comoro'
    | 'Indian/Mayotte'
    | 'Africa/Monrovia'
    | 'Africa/Tripoli'
    | 'Indian/Mauritius'
    | 'Africa/Casablanca'
    | 'Africa/El_Aaiun'
    | 'Africa/Maputo'
    | 'Africa/Blantyre'
    | 'Africa/Bujumbura'
    | 'Africa/Gaborone'
    | 'Africa/Harare'
    | 'Africa/Kigali'
    | 'Africa/Lubumbashi'
    | 'Africa/Lusaka'
    | 'Africa/Windhoek'
    | 'Africa/Lagos'
    | 'Africa/Bangui'
    | 'Africa/Brazzaville'
    | 'Africa/Douala'
    | 'Africa/Kinshasa'
    | 'Africa/Libreville'
    | 'Africa/Luanda'
    | 'Africa/Malabo'
    | 'Africa/Niamey'
    | 'Africa/Porto-Novo'
    | 'Indian/Reunion'
    | 'Africa/Sao_Tome'
    | 'Indian/Mahe'
    | 'Africa/Johannesburg'
    | 'Africa/Maseru'
    | 'Africa/Mbabane'
    | 'Africa/Khartoum'
    | 'Africa/Juba'
    | 'Africa/Tunis'
    | 'Antarctica/Casey'
    | 'Antarctica/Davis'
    | 'Antarctica/Mawson'
    | 'Indian/Kerguelen'
    | 'Antarctica/DumontDUrville'
    | 'Antarctica/Syowa'
    | 'Antarctica/Troll'
    | 'Antarctica/Vostok'
    | 'Antarctica/Rothera'
    | 'Asia/Kabul'
    | 'Asia/Yerevan'
    | 'Asia/Baku'
    | 'Asia/Dhaka'
    | 'Asia/Thimphu'
    | 'Indian/Chagos'
    | 'Asia/Brunei'
    | 'Asia/Yangon'
    | 'Asia/Shanghai'
    | 'Asia/Urumqi'
    | 'Asia/Hong_Kong'
    | 'Asia/Taipei'
    | 'Asia/Macau'
    | 'Asia/Nicosia'
    | 'Asia/Famagusta'
    | 'Europe/Nicosia'
    | 'Asia/Tbilisi'
    | 'Asia/Dili'
    | 'Asia/Kolkata'
    | 'Asia/Jakarta'
    | 'Asia/Pontianak'
    | 'Asia/Makassar'
    | 'Asia/Jayapura'
    | 'Asia/Tehran'
    | 'Asia/Baghdad'
    | 'Asia/Jerusalem'
    | 'Asia/Tokyo'
    | 'Asia/Amman'
    | 'Asia/Almaty'
    | 'Asia/Qyzylorda'
    | 'Asia/Qostanay'
    | 'Asia/Aqtobe'
    | 'Asia/Aqtau'
    | 'Asia/Atyrau'
    | 'Asia/Oral'
    | 'Asia/Bishkek'
    | 'Asia/Seoul'
    | 'Asia/Pyongyang'
    | 'Asia/Beirut'
    | 'Asia/Kuala_Lumpur'
    | 'Asia/Kuching'
    | 'Indian/Maldives'
    | 'Asia/Hovd'
    | 'Asia/Ulaanbaatar'
    | 'Asia/Choibalsan'
    | 'Asia/Kathmandu'
    | 'Asia/Karachi'
    | 'Asia/Gaza'
    | 'Asia/Hebron'
    | 'Asia/Manila'
    | 'Asia/Qatar'
    | 'Asia/Bahrain'
    | 'Asia/Riyadh'
    | 'Asia/Aden'
    | 'Asia/Kuwait'
    | 'Asia/Singapore'
    | 'Asia/Colombo'
    | 'Asia/Damascus'
    | 'Asia/Dushanbe'
    | 'Asia/Bangkok'
    | 'Asia/Phnom_Penh'
    | 'Asia/Vientiane'
    | 'Asia/Ashgabat'
    | 'Asia/Dubai'
    | 'Asia/Muscat'
    | 'Asia/Samarkand'
    | 'Asia/Tashkent'
    | 'Asia/Ho_Chi_Minh'
    | 'Australia/Darwin'
    | 'Australia/Perth'
    | 'Australia/Eucla'
    | 'Australia/Brisbane'
    | 'Australia/Lindeman'
    | 'Australia/Adelaide'
    | 'Australia/Hobart'
    | 'Australia/Currie'
    | 'Australia/Melbourne'
    | 'Australia/Sydney'
    | 'Australia/Broken_Hill'
    | 'Australia/Lord_Howe'
    | 'Antarctica/Macquarie'
    | 'Indian/Christmas'
    | 'Indian/Cocos'
    | 'Pacific/Fiji'
    | 'Pacific/Gambier'
    | 'Pacific/Marquesas'
    | 'Pacific/Tahiti'
    | 'Pacific/Guam'
    | 'Pacific/Saipan'
    | 'Pacific/Tarawa'
    | 'Pacific/Enderbury'
    | 'Pacific/Kiritimati'
    | 'Pacific/Majuro'
    | 'Pacific/Kwajalein'
    | 'Pacific/Chuuk'
    | 'Pacific/Pohnpei'
    | 'Pacific/Kosrae'
    | 'Pacific/Nauru'
    | 'Pacific/Noumea'
    | 'Pacific/Auckland'
    | 'Pacific/Chatham'
    | 'Antarctica/McMurdo'
    | 'Pacific/Rarotonga'
    | 'Pacific/Niue'
    | 'Pacific/Norfolk'
    | 'Pacific/Palau'
    | 'Pacific/Port_Moresby'
    | 'Pacific/Bougainville'
    | 'Pacific/Pitcairn'
    | 'Pacific/Pago_Pago'
    | 'Pacific/Midway'
    | 'Pacific/Apia'
    | 'Pacific/Guadalcanal'
    | 'Pacific/Fakaofo'
    | 'Pacific/Tongatapu'
    | 'Pacific/Funafuti'
    | 'Pacific/Wake'
    | 'Pacific/Efate'
    | 'Pacific/Wallis'
    | 'Africa/Asmera'
    | 'Africa/Timbuktu'
    | 'America/Argentina/ComodRivadavia'
    | 'America/Atka'
    | 'America/Buenos_Aires'
    | 'America/Catamarca'
    | 'America/Coral_Harbour'
    | 'America/Cordoba'
    | 'America/Ensenada'
    | 'America/Fort_Wayne'
    | 'America/Indianapolis'
    | 'America/Jujuy'
    | 'America/Knox_IN'
    | 'America/Louisville'
    | 'America/Mendoza'
    | 'America/Montreal'
    | 'America/Porto_Acre'
    | 'America/Rosario'
    | 'America/Santa_Isabel'
    | 'America/Shiprock'
    | 'America/Virgin'
    | 'Antarctica/South_Pole'
    | 'Asia/Ashkhabad'
    | 'Asia/Calcutta'
    | 'Asia/Chongqing'
    | 'Asia/Chungking'
    | 'Asia/Dacca'
    | 'Asia/Harbin'
    | 'Asia/Kashgar'
    | 'Asia/Katmandu'
    | 'Asia/Macao'
    | 'Asia/Rangoon'
    | 'Asia/Saigon'
    | 'Asia/Tel_Aviv'
    | 'Asia/Thimbu'
    | 'Asia/Ujung_Pandang'
    | 'Asia/Ulan_Bator'
    | 'Atlantic/Faeroe'
    | 'Atlantic/Jan_Mayen'
    | 'Australia/ACT'
    | 'Australia/Canberra'
    | 'Australia/LHI'
    | 'Australia/NSW'
    | 'Australia/North'
    | 'Australia/Queensland'
    | 'Australia/South'
    | 'Australia/Tasmania'
    | 'Australia/Victoria'
    | 'Australia/West'
    | 'Australia/Yancowinna'
    | 'Brazil/Acre'
    | 'Brazil/DeNoronha'
    | 'Brazil/East'
    | 'Brazil/West'
    | 'Canada/Atlantic'
    | 'Canada/Central'
    | 'Canada/Eastern'
    | 'Canada/Mountain'
    | 'Canada/Newfoundland'
    | 'Canada/Pacific'
    | 'Canada/Saskatchewan'
    | 'Canada/Yukon'
    | 'Chile/Continental'
    | 'Chile/EasterIsland'
    | 'Cuba'
    | 'Egypt'
    | 'Eire'
    | 'Etc/UCT'
    | 'Europe/Belfast'
    | 'Europe/Tiraspol'
    | 'GB'
    | 'GB-Eire'
    | 'GMT+0'
    | 'GMT-0'
    | 'GMT0'
    | 'Greenwich'
    | 'Hongkong'
    | 'Iceland'
    | 'Iran'
    | 'Israel'
    | 'Jamaica'
    | 'Japan'
    | 'Kwajalein'
    | 'Libya'
    | 'Mexico/BajaNorte'
    | 'Mexico/BajaSur'
    | 'Mexico/General'
    | 'NZ'
    | 'NZ-CHAT'
    | 'Navajo'
    | 'PRC'
    | 'Pacific/Johnston'
    | 'Pacific/Ponape'
    | 'Pacific/Samoa'
    | 'Pacific/Truk'
    | 'Pacific/Yap'
    | 'Poland'
    | 'Portugal'
    | 'ROC'
    | 'ROK'
    | 'Singapore'
    | 'Turkey'
    | 'UCT'
    | 'US/Alaska'
    | 'US/Aleutian'
    | 'US/Arizona'
    | 'US/Central'
    | 'US/East-Indiana'
    | 'US/Eastern'
    | 'US/Hawaii'
    | 'US/Indiana-Starke'
    | 'US/Michigan'
    | 'US/Mountain'
    | 'US/Pacific'
    | 'US/Samoa'
    | 'UTC'
    | 'Universal'
    | 'W-SU'
    | 'Zulu'
    | 'Etc/GMT'
    | 'Etc/UTC'
    | 'GMT'
    | 'Etc/Universal'
    | 'Etc/Zulu'
    | 'Etc/Greenwich'
    | 'Etc/GMT-0'
    | 'Etc/GMT+0'
    | 'Etc/GMT0'
    | 'Etc/GMT-14'
    | 'Etc/GMT-13'
    | 'Etc/GMT-12'
    | 'Etc/GMT-11'
    | 'Etc/GMT-10'
    | 'Etc/GMT-9'
    | 'Etc/GMT-8'
    | 'Etc/GMT-7'
    | 'Etc/GMT-6'
    | 'Etc/GMT-5'
    | 'Etc/GMT-4'
    | 'Etc/GMT-3'
    | 'Etc/GMT-2'
    | 'Etc/GMT-1'
    | 'Etc/GMT+1'
    | 'Etc/GMT+2'
    | 'Etc/GMT+3'
    | 'Etc/GMT+4'
    | 'Etc/GMT+5'
    | 'Etc/GMT+6'
    | 'Etc/GMT+7'
    | 'Etc/GMT+8'
    | 'Etc/GMT+9'
    | 'Etc/GMT+10'
    | 'Etc/GMT+11'
    | 'Etc/GMT+12'
    | 'Europe/London'
    | 'Europe/Jersey'
    | 'Europe/Guernsey'
    | 'Europe/Isle_of_Man'
    | 'Europe/Dublin'
    | 'WET'
    | 'CET'
    | 'MET'
    | 'EET'
    | 'Europe/Tirane'
    | 'Europe/Andorra'
    | 'Europe/Vienna'
    | 'Europe/Minsk'
    | 'Europe/Brussels'
    | 'Europe/Sofia'
    | 'Europe/Prague'
    | 'Europe/Copenhagen'
    | 'Atlantic/Faroe'
    | 'America/Danmarkshavn'
    | 'America/Scoresbysund'
    | 'America/Godthab'
    | 'America/Thule'
    | 'Europe/Tallinn'
    | 'Europe/Helsinki'
    | 'Europe/Mariehamn'
    | 'Europe/Paris'
    | 'Europe/Berlin'
    | 'Europe/Busingen'
    | 'Europe/Gibraltar'
    | 'Europe/Athens'
    | 'Europe/Budapest'
    | 'Atlantic/Reykjavik'
    | 'Europe/Rome'
    | 'Europe/Vatican'
    | 'Europe/San_Marino'
    | 'Europe/Riga'
    | 'Europe/Vaduz'
    | 'Europe/Vilnius'
    | 'Europe/Luxembourg'
    | 'Europe/Malta'
    | 'Europe/Chisinau'
    | 'Europe/Monaco'
    | 'Europe/Amsterdam'
    | 'Europe/Oslo'
    | 'Arctic/Longyearbyen'
    | 'Europe/Warsaw'
    | 'Europe/Lisbon'
    | 'Atlantic/Azores'
    | 'Atlantic/Madeira'
    | 'Europe/Bucharest'
    | 'Europe/Kaliningrad'
    | 'Europe/Moscow'
    | 'Europe/Simferopol'
    | 'Europe/Astrakhan'
    | 'Europe/Volgograd'
    | 'Europe/Saratov'
    | 'Europe/Kirov'
    | 'Europe/Samara'
    | 'Europe/Ulyanovsk'
    | 'Asia/Yekaterinburg'
    | 'Asia/Omsk'
    | 'Asia/Barnaul'
    | 'Asia/Novosibirsk'
    | 'Asia/Tomsk'
    | 'Asia/Novokuznetsk'
    | 'Asia/Krasnoyarsk'
    | 'Asia/Irkutsk'
    | 'Asia/Chita'
    | 'Asia/Yakutsk'
    | 'Asia/Vladivostok'
    | 'Asia/Khandyga'
    | 'Asia/Sakhalin'
    | 'Asia/Magadan'
    | 'Asia/Srednekolymsk'
    | 'Asia/Ust-Nera'
    | 'Asia/Kamchatka'
    | 'Asia/Anadyr'
    | 'Europe/Belgrade'
    | 'Europe/Ljubljana'
    | 'Europe/Podgorica'
    | 'Europe/Sarajevo'
    | 'Europe/Skopje'
    | 'Europe/Zagreb'
    | 'Europe/Bratislava'
    | 'Europe/Madrid'
    | 'Africa/Ceuta'
    | 'Atlantic/Canary'
    | 'Europe/Stockholm'
    | 'Europe/Zurich'
    | 'Europe/Istanbul'
    | 'Asia/Istanbul'
    | 'Europe/Kiev'
    | 'Europe/Uzhgorod'
    | 'Europe/Zaporozhye'
    | 'Factory'
    | 'EST'
    | 'MST'
    | 'HST'
    | 'EST5EDT'
    | 'CST6CDT'
    | 'MST7MDT'
    | 'PST8PDT'
    | 'America/New_York'
    | 'America/Chicago'
    | 'America/North_Dakota/Center'
    | 'America/North_Dakota/New_Salem'
    | 'America/North_Dakota/Beulah'
    | 'America/Denver'
    | 'America/Los_Angeles'
    | 'America/Juneau'
    | 'America/Sitka'
    | 'America/Metlakatla'
    | 'America/Yakutat'
    | 'America/Anchorage'
    | 'America/Nome'
    | 'America/Adak'
    | 'Pacific/Honolulu'
    | 'America/Phoenix'
    | 'America/Boise'
    | 'America/Indiana/Indianapolis'
    | 'America/Indiana/Marengo'
    | 'America/Indiana/Vincennes'
    | 'America/Indiana/Tell_City'
    | 'America/Indiana/Petersburg'
    | 'America/Indiana/Knox'
    | 'America/Indiana/Winamac'
    | 'America/Indiana/Vevay'
    | 'America/Kentucky/Louisville'
    | 'America/Kentucky/Monticello'
    | 'America/Detroit'
    | 'America/Menominee'
    | 'America/St_Johns'
    | 'America/Goose_Bay'
    | 'America/Halifax'
    | 'America/Glace_Bay'
    | 'America/Moncton'
    | 'America/Blanc-Sablon'
    | 'America/Toronto'
    | 'America/Thunder_Bay'
    | 'America/Nipigon'
    | 'America/Rainy_River'
    | 'America/Atikokan'
    | 'America/Winnipeg'
    | 'America/Regina'
    | 'America/Swift_Current'
    | 'America/Edmonton'
    | 'America/Vancouver'
    | 'America/Dawson_Creek'
    | 'America/Fort_Nelson'
    | 'America/Creston'
    | 'America/Pangnirtung'
    | 'America/Iqaluit'
    | 'America/Resolute'
    | 'America/Rankin_Inlet'
    | 'America/Cambridge_Bay'
    | 'America/Yellowknife'
    | 'America/Inuvik'
    | 'America/Whitehorse'
    | 'America/Dawson'
    | 'America/Cancun'
    | 'America/Merida'
    | 'America/Matamoros'
    | 'America/Monterrey'
    | 'America/Mexico_City'
    | 'America/Ojinaga'
    | 'America/Chihuahua'
    | 'America/Hermosillo'
    | 'America/Mazatlan'
    | 'America/Bahia_Banderas'
    | 'America/Tijuana'
    | 'America/Nassau'
    | 'America/Barbados'
    | 'America/Belize'
    | 'Atlantic/Bermuda'
    | 'America/Costa_Rica'
    | 'America/Havana'
    | 'America/Santo_Domingo'
    | 'America/El_Salvador'
    | 'America/Guatemala'
    | 'America/Port-au-Prince'
    | 'America/Tegucigalpa'
    | 'America/Jamaica'
    | 'America/Martinique'
    | 'America/Managua'
    | 'America/Panama'
    | 'America/Cayman'
    | 'America/Puerto_Rico'
    | 'America/Miquelon'
    | 'America/Grand_Turk'
    | 'US/Pacific-New'
    | 'America/Argentina/Buenos_Aires'
    | 'America/Argentina/Cordoba'
    | 'America/Argentina/Salta'
    | 'America/Argentina/Tucuman'
    | 'America/Argentina/La_Rioja'
    | 'America/Argentina/San_Juan'
    | 'America/Argentina/Jujuy'
    | 'America/Argentina/Catamarca'
    | 'America/Argentina/Mendoza'
    | 'America/Argentina/San_Luis'
    | 'America/Argentina/Rio_Gallegos'
    | 'America/Argentina/Ushuaia'
    | 'America/Aruba'
    | 'America/La_Paz'
    | 'America/Noronha'
    | 'America/Belem'
    | 'America/Santarem'
    | 'America/Fortaleza'
    | 'America/Recife'
    | 'America/Araguaina'
    | 'America/Maceio'
    | 'America/Bahia'
    | 'America/Sao_Paulo'
    | 'America/Campo_Grande'
    | 'America/Cuiaba'
    | 'America/Porto_Velho'
    | 'America/Boa_Vista'
    | 'America/Manaus'
    | 'America/Eirunepe'
    | 'America/Rio_Branco'
    | 'America/Santiago'
    | 'America/Punta_Arenas'
    | 'Pacific/Easter'
    | 'Antarctica/Palmer'
    | 'America/Bogota'
    | 'America/Curacao'
    | 'America/Lower_Princes'
    | 'America/Kralendijk'
    | 'America/Guayaquil'
    | 'Pacific/Galapagos'
    | 'Atlantic/Stanley'
    | 'America/Cayenne'
    | 'America/Guyana'
    | 'America/Asuncion'
    | 'America/Lima'
    | 'Atlantic/South_Georgia'
    | 'America/Paramaribo'
    | 'America/Port_of_Spain'
    | 'America/Anguilla'
    | 'America/Antigua'
    | 'America/Dominica'
    | 'America/Grenada'
    | 'America/Guadeloupe'
    | 'America/Marigot'
    | 'America/Montserrat'
    | 'America/St_Barthelemy'
    | 'America/St_Kitts'
    | 'America/St_Lucia'
    | 'America/St_Thomas'
    | 'America/St_Vincent'
    | 'America/Tortola'
    | 'America/Montevideo'
    | 'America/Caracas';
  usesCustomLogo: boolean;
  paymentRequestText: string;
  invoiceTypes?: InvoiceType[];
  students?: Student[];
}

export interface CreateOrganizationDto {
  name: string;

  /**
   * @min 0
   * @max 365
   */
  annual_vacation_days?: number;
  holidays?: Record<string, number[]>;

  /** @min 0 */
  full_time_hours: number;

  /** @min 0 */
  lesson_duration_minutes: number;

  /**
   * @min 3
   * @max 5
   */
  progress_card_score_range?: number;
  banking_info?: BankingInfo;
  datatrans_configuration?: DatatransConfig;
  contact_info?: ContactInfo;
  customizations?: Customizations;
  sms_configuration: SMSConfiguration;
  lessonRequestConfiguration: LessonRequestConfiguration;
  timezone?:
    | 'Africa/Algiers'
    | 'Atlantic/Cape_Verde'
    | 'Africa/Ndjamena'
    | 'Africa/Abidjan'
    | 'Africa/Bamako'
    | 'Africa/Banjul'
    | 'Africa/Conakry'
    | 'Africa/Dakar'
    | 'Africa/Freetown'
    | 'Africa/Lome'
    | 'Africa/Nouakchott'
    | 'Africa/Ouagadougou'
    | 'Atlantic/St_Helena'
    | 'Africa/Cairo'
    | 'Africa/Accra'
    | 'Africa/Bissau'
    | 'Africa/Nairobi'
    | 'Africa/Addis_Ababa'
    | 'Africa/Asmara'
    | 'Africa/Dar_es_Salaam'
    | 'Africa/Djibouti'
    | 'Africa/Kampala'
    | 'Africa/Mogadishu'
    | 'Indian/Antananarivo'
    | 'Indian/Comoro'
    | 'Indian/Mayotte'
    | 'Africa/Monrovia'
    | 'Africa/Tripoli'
    | 'Indian/Mauritius'
    | 'Africa/Casablanca'
    | 'Africa/El_Aaiun'
    | 'Africa/Maputo'
    | 'Africa/Blantyre'
    | 'Africa/Bujumbura'
    | 'Africa/Gaborone'
    | 'Africa/Harare'
    | 'Africa/Kigali'
    | 'Africa/Lubumbashi'
    | 'Africa/Lusaka'
    | 'Africa/Windhoek'
    | 'Africa/Lagos'
    | 'Africa/Bangui'
    | 'Africa/Brazzaville'
    | 'Africa/Douala'
    | 'Africa/Kinshasa'
    | 'Africa/Libreville'
    | 'Africa/Luanda'
    | 'Africa/Malabo'
    | 'Africa/Niamey'
    | 'Africa/Porto-Novo'
    | 'Indian/Reunion'
    | 'Africa/Sao_Tome'
    | 'Indian/Mahe'
    | 'Africa/Johannesburg'
    | 'Africa/Maseru'
    | 'Africa/Mbabane'
    | 'Africa/Khartoum'
    | 'Africa/Juba'
    | 'Africa/Tunis'
    | 'Antarctica/Casey'
    | 'Antarctica/Davis'
    | 'Antarctica/Mawson'
    | 'Indian/Kerguelen'
    | 'Antarctica/DumontDUrville'
    | 'Antarctica/Syowa'
    | 'Antarctica/Troll'
    | 'Antarctica/Vostok'
    | 'Antarctica/Rothera'
    | 'Asia/Kabul'
    | 'Asia/Yerevan'
    | 'Asia/Baku'
    | 'Asia/Dhaka'
    | 'Asia/Thimphu'
    | 'Indian/Chagos'
    | 'Asia/Brunei'
    | 'Asia/Yangon'
    | 'Asia/Shanghai'
    | 'Asia/Urumqi'
    | 'Asia/Hong_Kong'
    | 'Asia/Taipei'
    | 'Asia/Macau'
    | 'Asia/Nicosia'
    | 'Asia/Famagusta'
    | 'Europe/Nicosia'
    | 'Asia/Tbilisi'
    | 'Asia/Dili'
    | 'Asia/Kolkata'
    | 'Asia/Jakarta'
    | 'Asia/Pontianak'
    | 'Asia/Makassar'
    | 'Asia/Jayapura'
    | 'Asia/Tehran'
    | 'Asia/Baghdad'
    | 'Asia/Jerusalem'
    | 'Asia/Tokyo'
    | 'Asia/Amman'
    | 'Asia/Almaty'
    | 'Asia/Qyzylorda'
    | 'Asia/Qostanay'
    | 'Asia/Aqtobe'
    | 'Asia/Aqtau'
    | 'Asia/Atyrau'
    | 'Asia/Oral'
    | 'Asia/Bishkek'
    | 'Asia/Seoul'
    | 'Asia/Pyongyang'
    | 'Asia/Beirut'
    | 'Asia/Kuala_Lumpur'
    | 'Asia/Kuching'
    | 'Indian/Maldives'
    | 'Asia/Hovd'
    | 'Asia/Ulaanbaatar'
    | 'Asia/Choibalsan'
    | 'Asia/Kathmandu'
    | 'Asia/Karachi'
    | 'Asia/Gaza'
    | 'Asia/Hebron'
    | 'Asia/Manila'
    | 'Asia/Qatar'
    | 'Asia/Bahrain'
    | 'Asia/Riyadh'
    | 'Asia/Aden'
    | 'Asia/Kuwait'
    | 'Asia/Singapore'
    | 'Asia/Colombo'
    | 'Asia/Damascus'
    | 'Asia/Dushanbe'
    | 'Asia/Bangkok'
    | 'Asia/Phnom_Penh'
    | 'Asia/Vientiane'
    | 'Asia/Ashgabat'
    | 'Asia/Dubai'
    | 'Asia/Muscat'
    | 'Asia/Samarkand'
    | 'Asia/Tashkent'
    | 'Asia/Ho_Chi_Minh'
    | 'Australia/Darwin'
    | 'Australia/Perth'
    | 'Australia/Eucla'
    | 'Australia/Brisbane'
    | 'Australia/Lindeman'
    | 'Australia/Adelaide'
    | 'Australia/Hobart'
    | 'Australia/Currie'
    | 'Australia/Melbourne'
    | 'Australia/Sydney'
    | 'Australia/Broken_Hill'
    | 'Australia/Lord_Howe'
    | 'Antarctica/Macquarie'
    | 'Indian/Christmas'
    | 'Indian/Cocos'
    | 'Pacific/Fiji'
    | 'Pacific/Gambier'
    | 'Pacific/Marquesas'
    | 'Pacific/Tahiti'
    | 'Pacific/Guam'
    | 'Pacific/Saipan'
    | 'Pacific/Tarawa'
    | 'Pacific/Enderbury'
    | 'Pacific/Kiritimati'
    | 'Pacific/Majuro'
    | 'Pacific/Kwajalein'
    | 'Pacific/Chuuk'
    | 'Pacific/Pohnpei'
    | 'Pacific/Kosrae'
    | 'Pacific/Nauru'
    | 'Pacific/Noumea'
    | 'Pacific/Auckland'
    | 'Pacific/Chatham'
    | 'Antarctica/McMurdo'
    | 'Pacific/Rarotonga'
    | 'Pacific/Niue'
    | 'Pacific/Norfolk'
    | 'Pacific/Palau'
    | 'Pacific/Port_Moresby'
    | 'Pacific/Bougainville'
    | 'Pacific/Pitcairn'
    | 'Pacific/Pago_Pago'
    | 'Pacific/Midway'
    | 'Pacific/Apia'
    | 'Pacific/Guadalcanal'
    | 'Pacific/Fakaofo'
    | 'Pacific/Tongatapu'
    | 'Pacific/Funafuti'
    | 'Pacific/Wake'
    | 'Pacific/Efate'
    | 'Pacific/Wallis'
    | 'Africa/Asmera'
    | 'Africa/Timbuktu'
    | 'America/Argentina/ComodRivadavia'
    | 'America/Atka'
    | 'America/Buenos_Aires'
    | 'America/Catamarca'
    | 'America/Coral_Harbour'
    | 'America/Cordoba'
    | 'America/Ensenada'
    | 'America/Fort_Wayne'
    | 'America/Indianapolis'
    | 'America/Jujuy'
    | 'America/Knox_IN'
    | 'America/Louisville'
    | 'America/Mendoza'
    | 'America/Montreal'
    | 'America/Porto_Acre'
    | 'America/Rosario'
    | 'America/Santa_Isabel'
    | 'America/Shiprock'
    | 'America/Virgin'
    | 'Antarctica/South_Pole'
    | 'Asia/Ashkhabad'
    | 'Asia/Calcutta'
    | 'Asia/Chongqing'
    | 'Asia/Chungking'
    | 'Asia/Dacca'
    | 'Asia/Harbin'
    | 'Asia/Kashgar'
    | 'Asia/Katmandu'
    | 'Asia/Macao'
    | 'Asia/Rangoon'
    | 'Asia/Saigon'
    | 'Asia/Tel_Aviv'
    | 'Asia/Thimbu'
    | 'Asia/Ujung_Pandang'
    | 'Asia/Ulan_Bator'
    | 'Atlantic/Faeroe'
    | 'Atlantic/Jan_Mayen'
    | 'Australia/ACT'
    | 'Australia/Canberra'
    | 'Australia/LHI'
    | 'Australia/NSW'
    | 'Australia/North'
    | 'Australia/Queensland'
    | 'Australia/South'
    | 'Australia/Tasmania'
    | 'Australia/Victoria'
    | 'Australia/West'
    | 'Australia/Yancowinna'
    | 'Brazil/Acre'
    | 'Brazil/DeNoronha'
    | 'Brazil/East'
    | 'Brazil/West'
    | 'Canada/Atlantic'
    | 'Canada/Central'
    | 'Canada/Eastern'
    | 'Canada/Mountain'
    | 'Canada/Newfoundland'
    | 'Canada/Pacific'
    | 'Canada/Saskatchewan'
    | 'Canada/Yukon'
    | 'Chile/Continental'
    | 'Chile/EasterIsland'
    | 'Cuba'
    | 'Egypt'
    | 'Eire'
    | 'Etc/UCT'
    | 'Europe/Belfast'
    | 'Europe/Tiraspol'
    | 'GB'
    | 'GB-Eire'
    | 'GMT+0'
    | 'GMT-0'
    | 'GMT0'
    | 'Greenwich'
    | 'Hongkong'
    | 'Iceland'
    | 'Iran'
    | 'Israel'
    | 'Jamaica'
    | 'Japan'
    | 'Kwajalein'
    | 'Libya'
    | 'Mexico/BajaNorte'
    | 'Mexico/BajaSur'
    | 'Mexico/General'
    | 'NZ'
    | 'NZ-CHAT'
    | 'Navajo'
    | 'PRC'
    | 'Pacific/Johnston'
    | 'Pacific/Ponape'
    | 'Pacific/Samoa'
    | 'Pacific/Truk'
    | 'Pacific/Yap'
    | 'Poland'
    | 'Portugal'
    | 'ROC'
    | 'ROK'
    | 'Singapore'
    | 'Turkey'
    | 'UCT'
    | 'US/Alaska'
    | 'US/Aleutian'
    | 'US/Arizona'
    | 'US/Central'
    | 'US/East-Indiana'
    | 'US/Eastern'
    | 'US/Hawaii'
    | 'US/Indiana-Starke'
    | 'US/Michigan'
    | 'US/Mountain'
    | 'US/Pacific'
    | 'US/Samoa'
    | 'UTC'
    | 'Universal'
    | 'W-SU'
    | 'Zulu'
    | 'Etc/GMT'
    | 'Etc/UTC'
    | 'GMT'
    | 'Etc/Universal'
    | 'Etc/Zulu'
    | 'Etc/Greenwich'
    | 'Etc/GMT-0'
    | 'Etc/GMT+0'
    | 'Etc/GMT0'
    | 'Etc/GMT-14'
    | 'Etc/GMT-13'
    | 'Etc/GMT-12'
    | 'Etc/GMT-11'
    | 'Etc/GMT-10'
    | 'Etc/GMT-9'
    | 'Etc/GMT-8'
    | 'Etc/GMT-7'
    | 'Etc/GMT-6'
    | 'Etc/GMT-5'
    | 'Etc/GMT-4'
    | 'Etc/GMT-3'
    | 'Etc/GMT-2'
    | 'Etc/GMT-1'
    | 'Etc/GMT+1'
    | 'Etc/GMT+2'
    | 'Etc/GMT+3'
    | 'Etc/GMT+4'
    | 'Etc/GMT+5'
    | 'Etc/GMT+6'
    | 'Etc/GMT+7'
    | 'Etc/GMT+8'
    | 'Etc/GMT+9'
    | 'Etc/GMT+10'
    | 'Etc/GMT+11'
    | 'Etc/GMT+12'
    | 'Europe/London'
    | 'Europe/Jersey'
    | 'Europe/Guernsey'
    | 'Europe/Isle_of_Man'
    | 'Europe/Dublin'
    | 'WET'
    | 'CET'
    | 'MET'
    | 'EET'
    | 'Europe/Tirane'
    | 'Europe/Andorra'
    | 'Europe/Vienna'
    | 'Europe/Minsk'
    | 'Europe/Brussels'
    | 'Europe/Sofia'
    | 'Europe/Prague'
    | 'Europe/Copenhagen'
    | 'Atlantic/Faroe'
    | 'America/Danmarkshavn'
    | 'America/Scoresbysund'
    | 'America/Godthab'
    | 'America/Thule'
    | 'Europe/Tallinn'
    | 'Europe/Helsinki'
    | 'Europe/Mariehamn'
    | 'Europe/Paris'
    | 'Europe/Berlin'
    | 'Europe/Busingen'
    | 'Europe/Gibraltar'
    | 'Europe/Athens'
    | 'Europe/Budapest'
    | 'Atlantic/Reykjavik'
    | 'Europe/Rome'
    | 'Europe/Vatican'
    | 'Europe/San_Marino'
    | 'Europe/Riga'
    | 'Europe/Vaduz'
    | 'Europe/Vilnius'
    | 'Europe/Luxembourg'
    | 'Europe/Malta'
    | 'Europe/Chisinau'
    | 'Europe/Monaco'
    | 'Europe/Amsterdam'
    | 'Europe/Oslo'
    | 'Arctic/Longyearbyen'
    | 'Europe/Warsaw'
    | 'Europe/Lisbon'
    | 'Atlantic/Azores'
    | 'Atlantic/Madeira'
    | 'Europe/Bucharest'
    | 'Europe/Kaliningrad'
    | 'Europe/Moscow'
    | 'Europe/Simferopol'
    | 'Europe/Astrakhan'
    | 'Europe/Volgograd'
    | 'Europe/Saratov'
    | 'Europe/Kirov'
    | 'Europe/Samara'
    | 'Europe/Ulyanovsk'
    | 'Asia/Yekaterinburg'
    | 'Asia/Omsk'
    | 'Asia/Barnaul'
    | 'Asia/Novosibirsk'
    | 'Asia/Tomsk'
    | 'Asia/Novokuznetsk'
    | 'Asia/Krasnoyarsk'
    | 'Asia/Irkutsk'
    | 'Asia/Chita'
    | 'Asia/Yakutsk'
    | 'Asia/Vladivostok'
    | 'Asia/Khandyga'
    | 'Asia/Sakhalin'
    | 'Asia/Magadan'
    | 'Asia/Srednekolymsk'
    | 'Asia/Ust-Nera'
    | 'Asia/Kamchatka'
    | 'Asia/Anadyr'
    | 'Europe/Belgrade'
    | 'Europe/Ljubljana'
    | 'Europe/Podgorica'
    | 'Europe/Sarajevo'
    | 'Europe/Skopje'
    | 'Europe/Zagreb'
    | 'Europe/Bratislava'
    | 'Europe/Madrid'
    | 'Africa/Ceuta'
    | 'Atlantic/Canary'
    | 'Europe/Stockholm'
    | 'Europe/Zurich'
    | 'Europe/Istanbul'
    | 'Asia/Istanbul'
    | 'Europe/Kiev'
    | 'Europe/Uzhgorod'
    | 'Europe/Zaporozhye'
    | 'Factory'
    | 'EST'
    | 'MST'
    | 'HST'
    | 'EST5EDT'
    | 'CST6CDT'
    | 'MST7MDT'
    | 'PST8PDT'
    | 'America/New_York'
    | 'America/Chicago'
    | 'America/North_Dakota/Center'
    | 'America/North_Dakota/New_Salem'
    | 'America/North_Dakota/Beulah'
    | 'America/Denver'
    | 'America/Los_Angeles'
    | 'America/Juneau'
    | 'America/Sitka'
    | 'America/Metlakatla'
    | 'America/Yakutat'
    | 'America/Anchorage'
    | 'America/Nome'
    | 'America/Adak'
    | 'Pacific/Honolulu'
    | 'America/Phoenix'
    | 'America/Boise'
    | 'America/Indiana/Indianapolis'
    | 'America/Indiana/Marengo'
    | 'America/Indiana/Vincennes'
    | 'America/Indiana/Tell_City'
    | 'America/Indiana/Petersburg'
    | 'America/Indiana/Knox'
    | 'America/Indiana/Winamac'
    | 'America/Indiana/Vevay'
    | 'America/Kentucky/Louisville'
    | 'America/Kentucky/Monticello'
    | 'America/Detroit'
    | 'America/Menominee'
    | 'America/St_Johns'
    | 'America/Goose_Bay'
    | 'America/Halifax'
    | 'America/Glace_Bay'
    | 'America/Moncton'
    | 'America/Blanc-Sablon'
    | 'America/Toronto'
    | 'America/Thunder_Bay'
    | 'America/Nipigon'
    | 'America/Rainy_River'
    | 'America/Atikokan'
    | 'America/Winnipeg'
    | 'America/Regina'
    | 'America/Swift_Current'
    | 'America/Edmonton'
    | 'America/Vancouver'
    | 'America/Dawson_Creek'
    | 'America/Fort_Nelson'
    | 'America/Creston'
    | 'America/Pangnirtung'
    | 'America/Iqaluit'
    | 'America/Resolute'
    | 'America/Rankin_Inlet'
    | 'America/Cambridge_Bay'
    | 'America/Yellowknife'
    | 'America/Inuvik'
    | 'America/Whitehorse'
    | 'America/Dawson'
    | 'America/Cancun'
    | 'America/Merida'
    | 'America/Matamoros'
    | 'America/Monterrey'
    | 'America/Mexico_City'
    | 'America/Ojinaga'
    | 'America/Chihuahua'
    | 'America/Hermosillo'
    | 'America/Mazatlan'
    | 'America/Bahia_Banderas'
    | 'America/Tijuana'
    | 'America/Nassau'
    | 'America/Barbados'
    | 'America/Belize'
    | 'Atlantic/Bermuda'
    | 'America/Costa_Rica'
    | 'America/Havana'
    | 'America/Santo_Domingo'
    | 'America/El_Salvador'
    | 'America/Guatemala'
    | 'America/Port-au-Prince'
    | 'America/Tegucigalpa'
    | 'America/Jamaica'
    | 'America/Martinique'
    | 'America/Managua'
    | 'America/Panama'
    | 'America/Cayman'
    | 'America/Puerto_Rico'
    | 'America/Miquelon'
    | 'America/Grand_Turk'
    | 'US/Pacific-New'
    | 'America/Argentina/Buenos_Aires'
    | 'America/Argentina/Cordoba'
    | 'America/Argentina/Salta'
    | 'America/Argentina/Tucuman'
    | 'America/Argentina/La_Rioja'
    | 'America/Argentina/San_Juan'
    | 'America/Argentina/Jujuy'
    | 'America/Argentina/Catamarca'
    | 'America/Argentina/Mendoza'
    | 'America/Argentina/San_Luis'
    | 'America/Argentina/Rio_Gallegos'
    | 'America/Argentina/Ushuaia'
    | 'America/Aruba'
    | 'America/La_Paz'
    | 'America/Noronha'
    | 'America/Belem'
    | 'America/Santarem'
    | 'America/Fortaleza'
    | 'America/Recife'
    | 'America/Araguaina'
    | 'America/Maceio'
    | 'America/Bahia'
    | 'America/Sao_Paulo'
    | 'America/Campo_Grande'
    | 'America/Cuiaba'
    | 'America/Porto_Velho'
    | 'America/Boa_Vista'
    | 'America/Manaus'
    | 'America/Eirunepe'
    | 'America/Rio_Branco'
    | 'America/Santiago'
    | 'America/Punta_Arenas'
    | 'Pacific/Easter'
    | 'Antarctica/Palmer'
    | 'America/Bogota'
    | 'America/Curacao'
    | 'America/Lower_Princes'
    | 'America/Kralendijk'
    | 'America/Guayaquil'
    | 'Pacific/Galapagos'
    | 'Atlantic/Stanley'
    | 'America/Cayenne'
    | 'America/Guyana'
    | 'America/Asuncion'
    | 'America/Lima'
    | 'Atlantic/South_Georgia'
    | 'America/Paramaribo'
    | 'America/Port_of_Spain'
    | 'America/Anguilla'
    | 'America/Antigua'
    | 'America/Dominica'
    | 'America/Grenada'
    | 'America/Guadeloupe'
    | 'America/Marigot'
    | 'America/Montserrat'
    | 'America/St_Barthelemy'
    | 'America/St_Kitts'
    | 'America/St_Lucia'
    | 'America/St_Thomas'
    | 'America/St_Vincent'
    | 'America/Tortola'
    | 'America/Montevideo'
    | 'America/Caracas';
  paymentRequestText?: string;
}

export enum InvoiceSort {
  INSTRUCTOR = 'INSTRUCTOR',
  DUE_DATE = 'DUE_DATE',
}

export interface UpdateOrganizationDto {
  name?: string;

  /**
   * @min 0
   * @max 365
   */
  annual_vacation_days?: number;
  holidays?: Record<string, number[]>;

  /** @min 0 */
  full_time_hours?: number;

  /** @min 0 */
  lesson_duration_minutes?: number;

  /**
   * @min 3
   * @max 5
   */
  progress_card_score_range?: number;
  banking_info?: BankingInfo;
  datatrans_configuration?: DatatransConfig;
  contact_info?: ContactInfo;
  customizations?: Customizations;
  sms_configuration?: SMSConfiguration;
  lessonRequestConfiguration?: LessonRequestConfiguration;
  timezone?:
    | 'Africa/Algiers'
    | 'Atlantic/Cape_Verde'
    | 'Africa/Ndjamena'
    | 'Africa/Abidjan'
    | 'Africa/Bamako'
    | 'Africa/Banjul'
    | 'Africa/Conakry'
    | 'Africa/Dakar'
    | 'Africa/Freetown'
    | 'Africa/Lome'
    | 'Africa/Nouakchott'
    | 'Africa/Ouagadougou'
    | 'Atlantic/St_Helena'
    | 'Africa/Cairo'
    | 'Africa/Accra'
    | 'Africa/Bissau'
    | 'Africa/Nairobi'
    | 'Africa/Addis_Ababa'
    | 'Africa/Asmara'
    | 'Africa/Dar_es_Salaam'
    | 'Africa/Djibouti'
    | 'Africa/Kampala'
    | 'Africa/Mogadishu'
    | 'Indian/Antananarivo'
    | 'Indian/Comoro'
    | 'Indian/Mayotte'
    | 'Africa/Monrovia'
    | 'Africa/Tripoli'
    | 'Indian/Mauritius'
    | 'Africa/Casablanca'
    | 'Africa/El_Aaiun'
    | 'Africa/Maputo'
    | 'Africa/Blantyre'
    | 'Africa/Bujumbura'
    | 'Africa/Gaborone'
    | 'Africa/Harare'
    | 'Africa/Kigali'
    | 'Africa/Lubumbashi'
    | 'Africa/Lusaka'
    | 'Africa/Windhoek'
    | 'Africa/Lagos'
    | 'Africa/Bangui'
    | 'Africa/Brazzaville'
    | 'Africa/Douala'
    | 'Africa/Kinshasa'
    | 'Africa/Libreville'
    | 'Africa/Luanda'
    | 'Africa/Malabo'
    | 'Africa/Niamey'
    | 'Africa/Porto-Novo'
    | 'Indian/Reunion'
    | 'Africa/Sao_Tome'
    | 'Indian/Mahe'
    | 'Africa/Johannesburg'
    | 'Africa/Maseru'
    | 'Africa/Mbabane'
    | 'Africa/Khartoum'
    | 'Africa/Juba'
    | 'Africa/Tunis'
    | 'Antarctica/Casey'
    | 'Antarctica/Davis'
    | 'Antarctica/Mawson'
    | 'Indian/Kerguelen'
    | 'Antarctica/DumontDUrville'
    | 'Antarctica/Syowa'
    | 'Antarctica/Troll'
    | 'Antarctica/Vostok'
    | 'Antarctica/Rothera'
    | 'Asia/Kabul'
    | 'Asia/Yerevan'
    | 'Asia/Baku'
    | 'Asia/Dhaka'
    | 'Asia/Thimphu'
    | 'Indian/Chagos'
    | 'Asia/Brunei'
    | 'Asia/Yangon'
    | 'Asia/Shanghai'
    | 'Asia/Urumqi'
    | 'Asia/Hong_Kong'
    | 'Asia/Taipei'
    | 'Asia/Macau'
    | 'Asia/Nicosia'
    | 'Asia/Famagusta'
    | 'Europe/Nicosia'
    | 'Asia/Tbilisi'
    | 'Asia/Dili'
    | 'Asia/Kolkata'
    | 'Asia/Jakarta'
    | 'Asia/Pontianak'
    | 'Asia/Makassar'
    | 'Asia/Jayapura'
    | 'Asia/Tehran'
    | 'Asia/Baghdad'
    | 'Asia/Jerusalem'
    | 'Asia/Tokyo'
    | 'Asia/Amman'
    | 'Asia/Almaty'
    | 'Asia/Qyzylorda'
    | 'Asia/Qostanay'
    | 'Asia/Aqtobe'
    | 'Asia/Aqtau'
    | 'Asia/Atyrau'
    | 'Asia/Oral'
    | 'Asia/Bishkek'
    | 'Asia/Seoul'
    | 'Asia/Pyongyang'
    | 'Asia/Beirut'
    | 'Asia/Kuala_Lumpur'
    | 'Asia/Kuching'
    | 'Indian/Maldives'
    | 'Asia/Hovd'
    | 'Asia/Ulaanbaatar'
    | 'Asia/Choibalsan'
    | 'Asia/Kathmandu'
    | 'Asia/Karachi'
    | 'Asia/Gaza'
    | 'Asia/Hebron'
    | 'Asia/Manila'
    | 'Asia/Qatar'
    | 'Asia/Bahrain'
    | 'Asia/Riyadh'
    | 'Asia/Aden'
    | 'Asia/Kuwait'
    | 'Asia/Singapore'
    | 'Asia/Colombo'
    | 'Asia/Damascus'
    | 'Asia/Dushanbe'
    | 'Asia/Bangkok'
    | 'Asia/Phnom_Penh'
    | 'Asia/Vientiane'
    | 'Asia/Ashgabat'
    | 'Asia/Dubai'
    | 'Asia/Muscat'
    | 'Asia/Samarkand'
    | 'Asia/Tashkent'
    | 'Asia/Ho_Chi_Minh'
    | 'Australia/Darwin'
    | 'Australia/Perth'
    | 'Australia/Eucla'
    | 'Australia/Brisbane'
    | 'Australia/Lindeman'
    | 'Australia/Adelaide'
    | 'Australia/Hobart'
    | 'Australia/Currie'
    | 'Australia/Melbourne'
    | 'Australia/Sydney'
    | 'Australia/Broken_Hill'
    | 'Australia/Lord_Howe'
    | 'Antarctica/Macquarie'
    | 'Indian/Christmas'
    | 'Indian/Cocos'
    | 'Pacific/Fiji'
    | 'Pacific/Gambier'
    | 'Pacific/Marquesas'
    | 'Pacific/Tahiti'
    | 'Pacific/Guam'
    | 'Pacific/Saipan'
    | 'Pacific/Tarawa'
    | 'Pacific/Enderbury'
    | 'Pacific/Kiritimati'
    | 'Pacific/Majuro'
    | 'Pacific/Kwajalein'
    | 'Pacific/Chuuk'
    | 'Pacific/Pohnpei'
    | 'Pacific/Kosrae'
    | 'Pacific/Nauru'
    | 'Pacific/Noumea'
    | 'Pacific/Auckland'
    | 'Pacific/Chatham'
    | 'Antarctica/McMurdo'
    | 'Pacific/Rarotonga'
    | 'Pacific/Niue'
    | 'Pacific/Norfolk'
    | 'Pacific/Palau'
    | 'Pacific/Port_Moresby'
    | 'Pacific/Bougainville'
    | 'Pacific/Pitcairn'
    | 'Pacific/Pago_Pago'
    | 'Pacific/Midway'
    | 'Pacific/Apia'
    | 'Pacific/Guadalcanal'
    | 'Pacific/Fakaofo'
    | 'Pacific/Tongatapu'
    | 'Pacific/Funafuti'
    | 'Pacific/Wake'
    | 'Pacific/Efate'
    | 'Pacific/Wallis'
    | 'Africa/Asmera'
    | 'Africa/Timbuktu'
    | 'America/Argentina/ComodRivadavia'
    | 'America/Atka'
    | 'America/Buenos_Aires'
    | 'America/Catamarca'
    | 'America/Coral_Harbour'
    | 'America/Cordoba'
    | 'America/Ensenada'
    | 'America/Fort_Wayne'
    | 'America/Indianapolis'
    | 'America/Jujuy'
    | 'America/Knox_IN'
    | 'America/Louisville'
    | 'America/Mendoza'
    | 'America/Montreal'
    | 'America/Porto_Acre'
    | 'America/Rosario'
    | 'America/Santa_Isabel'
    | 'America/Shiprock'
    | 'America/Virgin'
    | 'Antarctica/South_Pole'
    | 'Asia/Ashkhabad'
    | 'Asia/Calcutta'
    | 'Asia/Chongqing'
    | 'Asia/Chungking'
    | 'Asia/Dacca'
    | 'Asia/Harbin'
    | 'Asia/Kashgar'
    | 'Asia/Katmandu'
    | 'Asia/Macao'
    | 'Asia/Rangoon'
    | 'Asia/Saigon'
    | 'Asia/Tel_Aviv'
    | 'Asia/Thimbu'
    | 'Asia/Ujung_Pandang'
    | 'Asia/Ulan_Bator'
    | 'Atlantic/Faeroe'
    | 'Atlantic/Jan_Mayen'
    | 'Australia/ACT'
    | 'Australia/Canberra'
    | 'Australia/LHI'
    | 'Australia/NSW'
    | 'Australia/North'
    | 'Australia/Queensland'
    | 'Australia/South'
    | 'Australia/Tasmania'
    | 'Australia/Victoria'
    | 'Australia/West'
    | 'Australia/Yancowinna'
    | 'Brazil/Acre'
    | 'Brazil/DeNoronha'
    | 'Brazil/East'
    | 'Brazil/West'
    | 'Canada/Atlantic'
    | 'Canada/Central'
    | 'Canada/Eastern'
    | 'Canada/Mountain'
    | 'Canada/Newfoundland'
    | 'Canada/Pacific'
    | 'Canada/Saskatchewan'
    | 'Canada/Yukon'
    | 'Chile/Continental'
    | 'Chile/EasterIsland'
    | 'Cuba'
    | 'Egypt'
    | 'Eire'
    | 'Etc/UCT'
    | 'Europe/Belfast'
    | 'Europe/Tiraspol'
    | 'GB'
    | 'GB-Eire'
    | 'GMT+0'
    | 'GMT-0'
    | 'GMT0'
    | 'Greenwich'
    | 'Hongkong'
    | 'Iceland'
    | 'Iran'
    | 'Israel'
    | 'Jamaica'
    | 'Japan'
    | 'Kwajalein'
    | 'Libya'
    | 'Mexico/BajaNorte'
    | 'Mexico/BajaSur'
    | 'Mexico/General'
    | 'NZ'
    | 'NZ-CHAT'
    | 'Navajo'
    | 'PRC'
    | 'Pacific/Johnston'
    | 'Pacific/Ponape'
    | 'Pacific/Samoa'
    | 'Pacific/Truk'
    | 'Pacific/Yap'
    | 'Poland'
    | 'Portugal'
    | 'ROC'
    | 'ROK'
    | 'Singapore'
    | 'Turkey'
    | 'UCT'
    | 'US/Alaska'
    | 'US/Aleutian'
    | 'US/Arizona'
    | 'US/Central'
    | 'US/East-Indiana'
    | 'US/Eastern'
    | 'US/Hawaii'
    | 'US/Indiana-Starke'
    | 'US/Michigan'
    | 'US/Mountain'
    | 'US/Pacific'
    | 'US/Samoa'
    | 'UTC'
    | 'Universal'
    | 'W-SU'
    | 'Zulu'
    | 'Etc/GMT'
    | 'Etc/UTC'
    | 'GMT'
    | 'Etc/Universal'
    | 'Etc/Zulu'
    | 'Etc/Greenwich'
    | 'Etc/GMT-0'
    | 'Etc/GMT+0'
    | 'Etc/GMT0'
    | 'Etc/GMT-14'
    | 'Etc/GMT-13'
    | 'Etc/GMT-12'
    | 'Etc/GMT-11'
    | 'Etc/GMT-10'
    | 'Etc/GMT-9'
    | 'Etc/GMT-8'
    | 'Etc/GMT-7'
    | 'Etc/GMT-6'
    | 'Etc/GMT-5'
    | 'Etc/GMT-4'
    | 'Etc/GMT-3'
    | 'Etc/GMT-2'
    | 'Etc/GMT-1'
    | 'Etc/GMT+1'
    | 'Etc/GMT+2'
    | 'Etc/GMT+3'
    | 'Etc/GMT+4'
    | 'Etc/GMT+5'
    | 'Etc/GMT+6'
    | 'Etc/GMT+7'
    | 'Etc/GMT+8'
    | 'Etc/GMT+9'
    | 'Etc/GMT+10'
    | 'Etc/GMT+11'
    | 'Etc/GMT+12'
    | 'Europe/London'
    | 'Europe/Jersey'
    | 'Europe/Guernsey'
    | 'Europe/Isle_of_Man'
    | 'Europe/Dublin'
    | 'WET'
    | 'CET'
    | 'MET'
    | 'EET'
    | 'Europe/Tirane'
    | 'Europe/Andorra'
    | 'Europe/Vienna'
    | 'Europe/Minsk'
    | 'Europe/Brussels'
    | 'Europe/Sofia'
    | 'Europe/Prague'
    | 'Europe/Copenhagen'
    | 'Atlantic/Faroe'
    | 'America/Danmarkshavn'
    | 'America/Scoresbysund'
    | 'America/Godthab'
    | 'America/Thule'
    | 'Europe/Tallinn'
    | 'Europe/Helsinki'
    | 'Europe/Mariehamn'
    | 'Europe/Paris'
    | 'Europe/Berlin'
    | 'Europe/Busingen'
    | 'Europe/Gibraltar'
    | 'Europe/Athens'
    | 'Europe/Budapest'
    | 'Atlantic/Reykjavik'
    | 'Europe/Rome'
    | 'Europe/Vatican'
    | 'Europe/San_Marino'
    | 'Europe/Riga'
    | 'Europe/Vaduz'
    | 'Europe/Vilnius'
    | 'Europe/Luxembourg'
    | 'Europe/Malta'
    | 'Europe/Chisinau'
    | 'Europe/Monaco'
    | 'Europe/Amsterdam'
    | 'Europe/Oslo'
    | 'Arctic/Longyearbyen'
    | 'Europe/Warsaw'
    | 'Europe/Lisbon'
    | 'Atlantic/Azores'
    | 'Atlantic/Madeira'
    | 'Europe/Bucharest'
    | 'Europe/Kaliningrad'
    | 'Europe/Moscow'
    | 'Europe/Simferopol'
    | 'Europe/Astrakhan'
    | 'Europe/Volgograd'
    | 'Europe/Saratov'
    | 'Europe/Kirov'
    | 'Europe/Samara'
    | 'Europe/Ulyanovsk'
    | 'Asia/Yekaterinburg'
    | 'Asia/Omsk'
    | 'Asia/Barnaul'
    | 'Asia/Novosibirsk'
    | 'Asia/Tomsk'
    | 'Asia/Novokuznetsk'
    | 'Asia/Krasnoyarsk'
    | 'Asia/Irkutsk'
    | 'Asia/Chita'
    | 'Asia/Yakutsk'
    | 'Asia/Vladivostok'
    | 'Asia/Khandyga'
    | 'Asia/Sakhalin'
    | 'Asia/Magadan'
    | 'Asia/Srednekolymsk'
    | 'Asia/Ust-Nera'
    | 'Asia/Kamchatka'
    | 'Asia/Anadyr'
    | 'Europe/Belgrade'
    | 'Europe/Ljubljana'
    | 'Europe/Podgorica'
    | 'Europe/Sarajevo'
    | 'Europe/Skopje'
    | 'Europe/Zagreb'
    | 'Europe/Bratislava'
    | 'Europe/Madrid'
    | 'Africa/Ceuta'
    | 'Atlantic/Canary'
    | 'Europe/Stockholm'
    | 'Europe/Zurich'
    | 'Europe/Istanbul'
    | 'Asia/Istanbul'
    | 'Europe/Kiev'
    | 'Europe/Uzhgorod'
    | 'Europe/Zaporozhye'
    | 'Factory'
    | 'EST'
    | 'MST'
    | 'HST'
    | 'EST5EDT'
    | 'CST6CDT'
    | 'MST7MDT'
    | 'PST8PDT'
    | 'America/New_York'
    | 'America/Chicago'
    | 'America/North_Dakota/Center'
    | 'America/North_Dakota/New_Salem'
    | 'America/North_Dakota/Beulah'
    | 'America/Denver'
    | 'America/Los_Angeles'
    | 'America/Juneau'
    | 'America/Sitka'
    | 'America/Metlakatla'
    | 'America/Yakutat'
    | 'America/Anchorage'
    | 'America/Nome'
    | 'America/Adak'
    | 'Pacific/Honolulu'
    | 'America/Phoenix'
    | 'America/Boise'
    | 'America/Indiana/Indianapolis'
    | 'America/Indiana/Marengo'
    | 'America/Indiana/Vincennes'
    | 'America/Indiana/Tell_City'
    | 'America/Indiana/Petersburg'
    | 'America/Indiana/Knox'
    | 'America/Indiana/Winamac'
    | 'America/Indiana/Vevay'
    | 'America/Kentucky/Louisville'
    | 'America/Kentucky/Monticello'
    | 'America/Detroit'
    | 'America/Menominee'
    | 'America/St_Johns'
    | 'America/Goose_Bay'
    | 'America/Halifax'
    | 'America/Glace_Bay'
    | 'America/Moncton'
    | 'America/Blanc-Sablon'
    | 'America/Toronto'
    | 'America/Thunder_Bay'
    | 'America/Nipigon'
    | 'America/Rainy_River'
    | 'America/Atikokan'
    | 'America/Winnipeg'
    | 'America/Regina'
    | 'America/Swift_Current'
    | 'America/Edmonton'
    | 'America/Vancouver'
    | 'America/Dawson_Creek'
    | 'America/Fort_Nelson'
    | 'America/Creston'
    | 'America/Pangnirtung'
    | 'America/Iqaluit'
    | 'America/Resolute'
    | 'America/Rankin_Inlet'
    | 'America/Cambridge_Bay'
    | 'America/Yellowknife'
    | 'America/Inuvik'
    | 'America/Whitehorse'
    | 'America/Dawson'
    | 'America/Cancun'
    | 'America/Merida'
    | 'America/Matamoros'
    | 'America/Monterrey'
    | 'America/Mexico_City'
    | 'America/Ojinaga'
    | 'America/Chihuahua'
    | 'America/Hermosillo'
    | 'America/Mazatlan'
    | 'America/Bahia_Banderas'
    | 'America/Tijuana'
    | 'America/Nassau'
    | 'America/Barbados'
    | 'America/Belize'
    | 'Atlantic/Bermuda'
    | 'America/Costa_Rica'
    | 'America/Havana'
    | 'America/Santo_Domingo'
    | 'America/El_Salvador'
    | 'America/Guatemala'
    | 'America/Port-au-Prince'
    | 'America/Tegucigalpa'
    | 'America/Jamaica'
    | 'America/Martinique'
    | 'America/Managua'
    | 'America/Panama'
    | 'America/Cayman'
    | 'America/Puerto_Rico'
    | 'America/Miquelon'
    | 'America/Grand_Turk'
    | 'US/Pacific-New'
    | 'America/Argentina/Buenos_Aires'
    | 'America/Argentina/Cordoba'
    | 'America/Argentina/Salta'
    | 'America/Argentina/Tucuman'
    | 'America/Argentina/La_Rioja'
    | 'America/Argentina/San_Juan'
    | 'America/Argentina/Jujuy'
    | 'America/Argentina/Catamarca'
    | 'America/Argentina/Mendoza'
    | 'America/Argentina/San_Luis'
    | 'America/Argentina/Rio_Gallegos'
    | 'America/Argentina/Ushuaia'
    | 'America/Aruba'
    | 'America/La_Paz'
    | 'America/Noronha'
    | 'America/Belem'
    | 'America/Santarem'
    | 'America/Fortaleza'
    | 'America/Recife'
    | 'America/Araguaina'
    | 'America/Maceio'
    | 'America/Bahia'
    | 'America/Sao_Paulo'
    | 'America/Campo_Grande'
    | 'America/Cuiaba'
    | 'America/Porto_Velho'
    | 'America/Boa_Vista'
    | 'America/Manaus'
    | 'America/Eirunepe'
    | 'America/Rio_Branco'
    | 'America/Santiago'
    | 'America/Punta_Arenas'
    | 'Pacific/Easter'
    | 'Antarctica/Palmer'
    | 'America/Bogota'
    | 'America/Curacao'
    | 'America/Lower_Princes'
    | 'America/Kralendijk'
    | 'America/Guayaquil'
    | 'Pacific/Galapagos'
    | 'Atlantic/Stanley'
    | 'America/Cayenne'
    | 'America/Guyana'
    | 'America/Asuncion'
    | 'America/Lima'
    | 'Atlantic/South_Georgia'
    | 'America/Paramaribo'
    | 'America/Port_of_Spain'
    | 'America/Anguilla'
    | 'America/Antigua'
    | 'America/Dominica'
    | 'America/Grenada'
    | 'America/Guadeloupe'
    | 'America/Marigot'
    | 'America/Montserrat'
    | 'America/St_Barthelemy'
    | 'America/St_Kitts'
    | 'America/St_Lucia'
    | 'America/St_Thomas'
    | 'America/St_Vincent'
    | 'America/Tortola'
    | 'America/Montevideo'
    | 'America/Caracas';
  paymentRequestText?: string;
}

export interface BillingTypeHistoryProps {
  predecessors: BillingType[];
  successors: BillingType[];
}

export interface CreateBillingTypeDto {
  licenseCategory: LicenseCategory;
  predecessor?: UUIDContainer;
  title: string;
  requiresDescription: boolean;
  trackable?: boolean;

  /** @min 0 */
  duration_minutes: number;

  /** @min 0 */
  price?: number;

  /** @min 0 */
  shadow_time?: number;
  organization: UUIDContainer;
  billingTypeTag?: UUIDContainer;
  endOfLife?: string;
  startOfLife?: string;
}

export interface UpdateBillingTypeDto {
  licenseCategory?: LicenseCategory;
  title?: string;
  requiresDescription?: boolean;
  trackable?: boolean;

  /** @min 0 */
  duration_minutes?: number;

  /** @min 0 */
  price?: number;

  /** @min 0 */
  shadow_time?: number;
  organization?: UUIDContainer;
  billingTypeTag?: UUIDContainer;
  endOfLife?: string;
  startOfLife?: string;
}

export interface CreateInvoiceDto {
  invoice_number?: number;
  finalized_at?: string;
  cancelled_on?: string | null;
  accounting_period_start?: string;
  accounting_period_end?: string;
  student: UUIDContainer;
  organization: UUIDContainer;
}

export interface UpdateInvoiceDto {
  invoice_number?: number;
  finalized_at?: string;
  cancelled_on?: string | null;
  accounting_period_start?: string;
  accounting_period_end?: string;
  student?: UUIDContainer;
  organization?: UUIDContainer;
}

export interface InvoiceRecipient {
  firstName: string;
  lastName: string;
  email: string;
  address: Address;
  phoneNumber?: string;
}

export interface InvoiceMailInfoDto {
  recipient: InvoiceRecipient;

  /**
   * @min 0
   * @max 100
   */
  percentage?: number;
}

export interface UserTimeTrackingBalance {
  user: User;
  totalVacationBalance: number;
  totalWorkBalance: number;
}

export interface TimesheetMonth {
  /**
   * @min 0
   * @max 11
   */
  month: number;
  expectedTime: number;
  actualTime: number;
  carryOver: number;
  worktimePercentage: number;
}

export interface HourSheet {
  months: TimesheetMonth[];
  previousYearCarryOver?: number;
  yearlyBalance?: number;
  currentBalance?: number;
}

export interface VacationSheet {
  months: number[];
  previousYearCarryOver?: number;
  annualVacationDays: number;
  yearlyBalance: number;
}

export interface Timesheet {
  id: string;
  year: string;
  user?: User;
  carryOverManualEdits: CarryOverManualEdit[];
  vacationManualEdits: CarryOverManualEdit[];
  hourSheet: HourSheet;
  vacationSheet: VacationSheet;
}

export interface CreateTimesheetDto {
  year: string;
  carryOverManualEdits?: CarryOverManualEdit[];
  vacationManualEdits?: CarryOverManualEdit[];
  user: UserIdContainer;
}

export interface UpdateTimesheetDto {
  year?: string;
  carryOverManualEdits?: CarryOverManualEdit[];
  vacationManualEdits?: CarryOverManualEdit[];
  user?: UserIdContainer;
}

export interface CreateLockPeriodDto {
  /** @min 1 */
  year: number;

  /**
   * @min 0
   * @max 11
   */
  month: number;
  action:
    | 'INVOICE_EDITS'
    | 'PAYMENT_EDITS'
    | 'CALENDAR_EDITS'
    | 'TIMESHEET_EDITS';
}

export enum LockableAction {
  INVOICE_EDITS = 'INVOICE_EDITS',
  PAYMENT_EDITS = 'PAYMENT_EDITS',
  CALENDAR_EDITS = 'CALENDAR_EDITS',
  TIMESHEET_EDITS = 'TIMESHEET_EDITS',
}

export interface LockPeriod {
  action: LockableAction;
  year: number;
  month: number;
  organization: Organization;
  organizationId: string;
}

export interface CreateVehicleDto {
  license_plate: string;
  licenseCategory: LicenseCategory;
  secondaryLicenseCategory: LicenseCategory;
  title?: string;
  active: boolean;
  assigned_instructors?: UserIdContainer[];
  main_instructor?: UserIdContainer;
  additional_info?: VehicleInfo;
}

export interface UpdateVehicleDto {
  license_plate?: string;
  licenseCategory?: LicenseCategory;
  secondaryLicenseCategory?: LicenseCategory;
  title?: string;
  active?: boolean;
  assigned_instructors?: UserIdContainer[];
  main_instructor?: UserIdContainer;
  additional_info?: VehicleInfo;
}

export interface CreateICalSubscriptionDto {
  title: string;
  subscriptionUrl: string;
  display_color?: Color;
}

export interface GetICalsubscriptionDto {
  id: string;
  title: string;
  display_color: Color;
}

export interface UpdateICalSubscriptionDto {
  title?: string;
  subscriptionUrl?: string;
  display_color?: Color;
}

export interface CreateExamAttemptDto {
  /**
   * @min 1
   * @max 4
   */
  attempt_number: number;
  exam_date?: string;
  examiner: string;
  passed: boolean;
  comment?: string;
  exam: UUIDContainer;
  instructor?: UUIDContainer;
}

export interface UpdateExamAttemptDto {
  /**
   * @min 1
   * @max 4
   */
  attempt_number?: number;
  exam_date?: string;
  examiner?: string;
  passed?: boolean;
  comment?: string;
  exam?: UUIDContainer;
  instructor?: UUIDContainer;
}

export interface CreateExamDto {
  licenseCategory?:
    | 'A'
    | 'A1'
    | 'B'
    | 'BE'
    | 'C'
    | 'CE'
    | 'C1'
    | 'C1E'
    | 'D1'
    | 'D1E'
    | 'D'
    | 'F'
    | 'None';
  student: UUIDContainer;
}

export interface UpdateExamDto {
  licenseCategory?:
    | 'A'
    | 'A1'
    | 'B'
    | 'BE'
    | 'C'
    | 'CE'
    | 'C1'
    | 'C1E'
    | 'D1'
    | 'D1E'
    | 'D'
    | 'F'
    | 'None';
  student?: UUIDContainer;
}

export interface CreateCalendarExportDto {
  access_type: 'full_access' | 'availability_only';
  label?: string;
}

export interface UpdateCalendarExportDto {
  access_type?: 'full_access' | 'availability_only';
  label?: string;
}

export interface CreateDropOutItemDto {
  student: UUIDContainer;
  reason: UUIDContainer;
  date: string;
  remark?: string | null;
}

export interface UpdateDropOutItemDto {
  student?: UUIDContainer;
  reason?: UUIDContainer;
  date?: string;
  remark?: string | null;
}

export interface CreateDropOutReasonDto {
  text: string;
}

export interface UpdateDropOutReasonDto {
  text?: string;
}

export interface CreateRoleDto {
  dashboardComponents: DashboardComponent[];
  title: string;
  permissions: object;
  isAdmin: boolean;
}

export interface UpdateRoleDto {
  dashboardComponents?: DashboardComponent[];
  title?: string;
  permissions?: object;
  isAdmin?: boolean;
}

export interface CreateAppUsageContractDto {
  activeFrom?: string | null;
  activeUntil?: string | null;
  package?: UUIDContainer | null;
  FF_sms: boolean;
  FF_mailing: boolean;
  FF_student_login: boolean;
  FF_time_tracking: boolean;
  FF_finance_overview: boolean;
  FF_payment_processing: boolean;
  FF_syllabi: boolean;
  FF_payment_import: boolean;
  FF_invoice_deletion: boolean;

  /** @min 0 */
  UL_maxUsers?: number | null;

  /** @min 0 */
  UL_maxVehicles: number | null;
}

export interface UpdateAppUsageContractDto {
  FF_sms?: boolean;
  FF_mailing?: boolean;
  FF_student_login?: boolean;
  FF_time_tracking?: boolean;
  FF_finance_overview?: boolean;
  FF_payment_processing?: boolean;
  FF_syllabi?: boolean;
  FF_payment_import?: boolean;
  FF_invoice_deletion?: boolean;

  /** @min 0 */
  UL_maxUsers?: number | null;

  /** @min 0 */
  UL_maxVehicles?: number | null;
  activeFrom?: string | null;
  activeUntil?: string | null;
  package?: UUIDContainer | null;
}

export interface CreateLessonRequestDto {
  licenseCategory: LicenseCategory;

  /** @format date-time */
  completedDate?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  address: Address;
  requestedTimeframes: string[];
  requestedInstructor?: UserIdContainer;
  referrer?: string | null;
  message?: string | null;
  student?: UUIDContainer;
  reCaptchaToken: string;
}

export interface LessonRequestWithAssignment {
  licenseCategory: LicenseCategory;
  requestedTimeframes: string[];
  id: string;

  /** @format date-time */
  createdDate: string;

  /** @format date-time */
  completedDate: string | null;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  address: Address;
  requestedInstructor: User | null;
  organization: Organization;
  referrer: string | null;
  message: string | null;
  student: Student | null;
  assignmentHistory?: LessonRequestAssignmentHistory[];
  assignedInstructor?: User | null;
}

export interface UpdateLessonRequestDto {
  licenseCategory?: LicenseCategory;

  /** @format date-time */
  completedDate?: string | null;
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
  address?: Address;
  requestedTimeframes?: string[];
  requestedInstructor?: UserIdContainer;
  referrer?: string | null;
  message?: string | null;
  student?: UUIDContainer;
  reCaptchaToken?: string;
}

export interface CreateLessonRequestAssignmentHistoryDto {
  assignedBy?: UserIdContainer;
  assignedTo?: UserIdContainer;
  lessonRequest: UUIDContainer;
}

export enum ActionType {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface AuditLogEntry {
  actionType: ActionType;
  id: string;

  /** @format date-time */
  createdDate: string;
  organization?: Organization;
  event?: WorkEvent;
  eventStudent?: Student;
  eventInstructor?: User;
  eventBillingType?: BillingType;

  /** @format date-time */
  startTimeBeforeAction: string;

  /** @format date-time */
  endTimeBeforeAction: string;

  /** @format date-time */
  startTimeAfterAction?: string;

  /** @format date-time */
  endTimeAfterAction?: string;
  actor?: User;
}

export interface CreateProductDto {
  assignedForLicenseCategory?: LicenseCategory;
  title: string;
  price: number;
}

export interface UpdateProductDto {
  assignedForLicenseCategory?: LicenseCategory;
  title?: string;
  price?: number;
}

export interface CreateInvoiceHistoryItemDto {
  invoice: UUIDContainer;
  sent_date?: string;
  due_date?: string;
  invoice_type?: UUIDContainer;

  /**
   * @min 0
   * @max 100
   */
  percentage?: number | null;
  recipient_name?: string;
}

export interface CreateInvoicePositionDto {
  title: string;
  price: number;
  event?: UUIDContainer;
  product?: UUIDContainer;
  invoice: UUIDContainer;
  date?: string;
}

export interface UpdateInvoicePositionDto {
  title?: string;
  price?: number;
  event?: UUIDContainer;
  product?: UUIDContainer;
  invoice?: UUIDContainer;
  date?: string;
}

export interface PaymentRequestHistoryEntry {
  id: string;

  /** @format date-time */
  date: string;
  amount: number;
  positionTitle: string;
  student: Student;
  sender?: User;
}

export interface CreateStudentGroupDto {
  title: string;
  studentIds?: string[];
}

export interface UpdateStudentGroupDto {
  title?: string;
  studentIds?: string[];
}

export interface CreateSyllabusDto {
  licenseCategory: LicenseCategory;
  title: string;
  sections: string;
  archived?: boolean;
}

export interface UpdateSyllabusDto {
  licenseCategory?: LicenseCategory;
  title?: string;
  sections?: string;
  archived?: boolean;
}

export interface CreateLessonDto {
  title: string;
  syllabus: UUIDContainer;
}

export interface UpdateLessonDto {
  title?: string;
  syllabus?: UUIDContainer;
}

export interface CreateInvoiceTypeDto {
  description: string;

  /** @min 0 */
  due_date_days: number;
  title: string;
}

export interface UpdateInvoiceTypeDto {
  /** @min 0 */
  order?: number;
  active?: boolean;
  description?: string;

  /** @min 0 */
  due_date_days?: number;
  title?: string;
}

export interface CreateMeetingPointDto {
  title: string;
  address?: Address;
}

export interface UpdateMeetingPointDto {
  title?: string;
  address?: Address;
}

export interface CreateBillingTypeTagDto {
  title: string;
}

export interface UpdateBillingTypeTagDto {
  title?: string;
}

export interface CreatePaymentShortcutDto {
  /** @min 1 */
  amount: number;
  description: string;
}

export interface PaymentShortcut {
  id: string;
  amount: number;
  description: string;
  organization: Organization;
}

export interface UpdatePaymentShortcutDto {
  /** @min 1 */
  amount?: number;
  description?: string;
}

export interface CreatePaymentRequestShortcutDto {
  /** @min 1 */
  amount: number;
  description: string;
}

export interface PaymentRequestShortcut {
  id: string;
  amount: number;
  description: string;
  organization: Organization;
}

export interface UpdatePaymentRequestShortcutDto {
  /** @min 1 */
  amount?: number;
  description?: string;
}

export interface CreateExaminerDto {
  name: string;
}

export interface Examiner {
  id: string;
  name: string;
  organization: Organization;
}

export interface CreateAppUsagePackageDto {
  title: string;
  archived?: boolean;
}

export interface UpdateAppUsagePackageDto {
  title?: string;
  archived?: boolean;
}

export interface CreateTodoDto {
  createdBy: UserIdContainer;
  assignedUser: UserIdContainer;

  /** @format date-time */
  dueDate: string | null;

  /** @format date-time */
  doneDate: string | null;
  title: string;
  description: string;
}

export interface Todo {
  createdBy: User | null;
  id: string;
  assignedUser: User;

  /** @format date-time */
  createdDate: string;

  /** @format date-time */
  dueDate: string | null;
  title: string;
  description: string;

  /** @format date-time */
  doneDate: string | null;
}

export interface TodoStatusDto {
  /** @format date-time */
  doneDate: string | null;
}

export interface UpdateTodoDto {
  createdBy?: UserIdContainer;
  assignedUser?: UserIdContainer;

  /** @format date-time */
  dueDate?: string | null;

  /** @format date-time */
  doneDate?: string | null;
  title?: string;
  description?: string;
}

export enum TodoOrder {
  AssignedUser = 'assignedUser',
  DueDate = 'dueDate',
  CreatedDate = 'createdDate',
}

export interface CreateOfficeLocationDto {
  title: string;
  address?: Address;
}

export interface OfficeLocation {
  id: string;
  title: string;
  organization: Organization;
  address: Address;
}

export interface UpdateOfficeLocationDto {
  title?: string;
  address?: Address;
}
