import { PendingInvoiceReturnTo } from '../pages/students/[id]/pending-invoice';
import { queries } from './queries';

export class Routes {
  static Dashboard = '/';
  static Organization = {
    Holidays: '/organization/holidays',
    Products: {
      Overview: '/organization/products',
      DetailView: (id: string): string => '/organization/products/' + id,
      AddView: '/organization/products/add',
    },
    InvoiceSettings: {
      Overview: '/organization/invoice-settings',
      InvoiceTypes: {
        AddView: '/organization/invoice-settings/invoice-types/add',
        DetailView: (id: string): string =>
          '/organization/invoice-settings/invoice-types/' + id,
      },
    },
    MeetingPoints: {
      Overview: '/organization/meeting-points',
      AddView: '/organization/meeting-points/add',
      EditView: (id: string) => '/organization/meeting-points/' + id,
    },
    Examiners: {
      Overview: '/organization/examiners',
    },
    StudentGroups: {
      Overview: '/organization/student-groups',
      DetailView: (id: string): string => '/organization/student-groups/' + id,
      AddView: '/organization/student-groups/add',
    },
    DetailView: '/organization',
    EditView: '/organization/edit',
    DropoutReasons: {
      Overview: '/organization/dropout-reasons',
      DetailView: (id: string): string => '/organization/dropout-reasons/' + id,
      AddView: '/organization/dropout-reasons/add',
    },
    BillingTypeTags: {
      Overview: '/organization/billing-type-tags',
      DetailView: (id: string): string =>
        '/organization/billing-type-tags/' + id,
      AddView: '/organization/billing-type-tags/add',
    },
    PaymentSettings: {
      Overview: '/organization/payment-settings',
      PaymentShortcuts: {
        DetailView: (id: string): string =>
          '/organization/payment-settings/payment-shortcuts/' + id,
        AddView: '/organization/payment-settings/payment-shortcuts/add',
      },
      PaymentRequestShortcuts: {
        DetailView: (id: string): string =>
          '/organization/payment-settings/payment-request-shortcuts/' + id,
        AddView: '/organization/payment-settings/payment-request-shortcuts/add',
      },
    },
    CustomBranding: '/organization/custom-branding',
    LessonRequestSettings: {
      Index: '/organization/lesson-request-settings',
      EditReferrers: '/organization/lesson-request-settings/edit-referrers',
      EditTimeframes: '/organization/lesson-request-settings/edit-timeframes',
    },
    OfficeLocations: {
      Overview: '/organization/office-locations',
      AddView: '/organization/office-locations/add',
      EditView: (id: string) => '/organization/office-locations/' + id,
    },
    SMSSettings: '/organization/sms-settings',
    LockPeriods: '/organization/lock-periods',
    Roles: {
      Overview: '/organization/roles',
      AddView: '/organization/roles/add',
      DetailView: (id: string): string => '/organization/roles/' + id,
      EditView: (id: string): string => '/organization/roles/' + id + '/edit',
    },
  };
  static Students = {
    Overview: '/students',
    AddView: '/students/add',
    Archived: '/students/archived',
    ProgressCards: (studentId: string) => {
      return {
        Overview: `/students/${studentId}/syllabus-progress-cards`,
        DetailView: (cardId: string, returnToEventId?: string) =>
          cardId &&
          `/students/${studentId}/syllabus-progress-cards/${cardId}` +
            (returnToEventId ? `?returnToEventId=${returnToEventId}` : ''),
        EventView: (cardId: string, eventId: string) =>
          cardId &&
          `/students/${studentId}/syllabus-progress-cards/${cardId}/${eventId}`,
      };
    },
    Invoices: (studentId: string) => ({
      Overview: `/students/${studentId}/invoices`,
      DetailView: (id: string) => `/students/${studentId}/invoices/${id}`,
    }),
    Payments: (studentId: string) => ({
      Overview: `/students/${studentId}/payments`,
      AddView: `/students/${studentId}/payments/add`,
      InitiateView: `/students/${studentId}/payments/initiate`,
      DetailView: (id: string) => `/students/${studentId}/payments/${id}`,
    }),
    PendingInvoice: (
      studentId: string,
      returnTo?: PendingInvoiceReturnTo,
    ): string =>
      studentId &&
      `/students/${studentId}/pending-invoice` +
        (returnTo ? `?returnTo=${returnTo}` : ''),
    Lessons: (studentId: string): string =>
      studentId && `/students/${studentId}/lessons`,
    Exams: {
      Overview: (studentId: string): string =>
        studentId && `/students/${studentId}/exams`,
      DetailView: (
        studentId: string,
        examId: string,
        returnToEventId?: string,
      ): string =>
        studentId &&
        examId &&
        `/students/${studentId}/exams/${examId}` +
          (returnToEventId ? `?returnToEventId=${returnToEventId}` : ''),
      AddView: (studentId: string): string =>
        studentId && `/students/${studentId}/exams/add`,
      EditView: (studentId: string, examId: string): string =>
        studentId && examId && `/students/${studentId}/exams/${examId}/edit`,
    },
    FileAttachments: (studentId: string): string =>
      studentId && `/students/${studentId}/file-attachments`,
    DetailView: (studentId: string): string => `/students/${studentId}`,
    EditView: (studentId: string): string => `/students/${studentId}/edit`,
  };
  static Users = {
    Overview: '/users',
    Archived: '/users/archived',
    AddView: '/users/add',
    DetailView: (id: string): string => '/users/' + id,
    EditView: (id: string): string => '/users/' + id + '/edit',
    SuccessReport: (id: string): string => '/users/' + id + '/success-report',
  };
  static Syllabi = {
    Overview: '/syllabi',
    AddView: '/syllabi/add',
    DetailView: (id: string): string => '/syllabi/' + id,
  };
  static Profile = {
    Overview: '/profile',
    EditView: '/profile/edit',
  };
  static Invoices = {
    Overview: '/invoices',
    DetailView: (id: string) => `/invoices/${id}`,
  };
  static BillingTypes = {
    Overview: '/billing-types',
    Archived: '/billing-types/archived',
    DetailView: (id: string): string => '/billing-types/' + id,
    EditView: (id: string): string => '/billing-types/' + id + '/edit',
    NewVersionView: (id: string): string =>
      '/billing-types/' + id + '/new-version',
    AddView: '/billing-types/add',
    BillingTypeTags: {
      Overview: '/billing-types/billing-type-tags',
      DetailView: (id: string): string =>
        '/billing-types/billing-type-tags/' + id,
      AddView: '/billing-types/billing-type-tags/add',
    },
  };
  static Payments = {
    Overview: '/payments',
    DetailView: (id: string): string => '/payments/' + id,
  };
  // if an eventId is passed in, the calendar opend directly to the ViewPopup for that event
  static Calendar = {
    CalendarView: (eventId?: string, lessonRequestId?: string): string =>
      '/calendar' + queries({ event: eventId, lessonRequestId }),
    VacationCalendar: '/calendar/vacation-calendar',
    AuditLog: '/calendar/audit-log',
  };
  static TimeTracking = {
    Overview: (userId?: string) =>
      '/time-tracking' + (userId ? '?userId=' + userId : ''),
    DetailView: (
      userId: string,
      year: string | number,
      month: string | number,
    ) => `/time-tracking/${userId}/${year}/${month}`,
  };
  static SuccessReport = '/success-report';
  static AllSuccessReports = '/success-reports';
  static Vehicles = {
    Overview: '/vehicles',
    AddView: '/vehicles/add',
    DetailView: (vehicleId: string): string =>
      vehicleId && `/vehicles/${vehicleId}`,
    EditView: (vehicleId: string): string =>
      vehicleId && `/vehicles/${vehicleId}/edit`,
  };
  static ProgressOverview = {
    Overview: '/progress-overview',
    DetailView: (id: string): string => '/progress-overview/' + id,
  };
  static LessonRequests = {
    DetailView: (id: string): string => '/lesson-requests/' + id,
  };
  static Todos = {
    Overview: '/todo',
    Complete: '/todo/complete',
    DetailView: (id: string): string => '/todo/' + id,
  };
}
